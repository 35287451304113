import gql from 'graphql-tag'

const GET_ANSWERS_WITH_COMMENTS = gql`query getAnswersWithComments($formSetId: String!, $tenantId: String!) {
    answersWithComments: getAnswersWithComments(form_set_id: $formSetId, tenant_id: $tenantId) {
        answers
    }
}
`
const GET_ANSWER_COUNT = gql`query getAnswerCount(
    $formSetId: uuid,
    $tenantId: uuid,
    $startDate: timestamptz,
    $endDate: timestamptz
) {
    formAnswerCount: form_builder_hasura_form_answer_aggregate(
        where: {
            form_set_id: {
                _eq: $formSetId
            },
            tenant_id: {
                _eq: $tenantId
            },
            _and: [
                { created_at: { _lte: $endDate } },
                { created_at: { _gte: $startDate } }
            ]
        }
    ) {
        aggregate {
            count
        }
    }
}
`

export {
    GET_ANSWERS_WITH_COMMENTS,
    GET_ANSWER_COUNT
}
