<template lang="pug">
    v-expansion-panels(
        accordion
        multiple
        v-model="panelModel"
        v-bind="$attrs"
    )
        v-expansion-panel(v-for="(category, index) in categories" :key="index")
            v-expansion-panel-header(class="font-weight-bold text-off-white p-3")
                | {{ category.name }}
            v-expansion-panel-content
                v-data-table(
                    :headers="headers"
                    :items="getItemsByCategory(category.categoryId)"
                    :loading="loading"
                    :loading-text="loadingText"
                    :items-per-page.sync="itemsPerPage"
                    :footer-props="footerProps"
                    @click:row="rowClickHandler"
                )
                    template(v-slot:item.excellent="{item}")
                        div(
                            :class="getHighestClasses(item, 'excellent')"
                        )  {{ item.excellent || 0 }}
                    template(v-slot:item.fair="{item}")
                        div(
                            :class="getHighestClasses(item, 'fair')"
                        )  {{ item.fair || 0 }}
                    template(v-slot:item.good="{item}")
                        div(
                            :class="getHighestClasses(item, 'good')"
                        )  {{ item.good || 0 }}
                    template(v-slot:item.poor="{item}")
                        div(
                            :class="getHighestClasses(item, 'poor')"
                        )  {{ item.poor || 0 }}
                    template(v-slot:item.veryGood="{item}")
                        div(
                            :class="getHighestClasses(item, 'veryGood')"
                        ) {{ item.veryGood || 0 }}
                    template(v-slot:item.na="{item}")
                        div(
                            :class="getHighestClasses(item, 'na')"
                        ) {{ item.na || 0 }}
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
    name: 'FormDataTable',
    props: {
        headers: {
            type: Array,
            default: () => []
        },
        items: {
            type: Array,
            default: () => []
        },
        categories: {
            type: Array,
            default: () => []
        },
        rowClickHandler: {
            type: Function,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: false
        },
        loadingText: {
            type: String,
            default: ''
        },
        rowLoading: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const expanded = ref([])
        const itemsPerPage = ref(10)
        const footerProps = ref({
            'items-per-page-options': [10, 25, 50, 100]
        })
        const headersCategory = ref([
            { text: 'Category name', align: 'start', value: 'name', class: '', sortable: false },
        ])
        const panelModel = ref([0])

        console.log('items loaded', props.items)

        const isHighest = (item, key) => {
            const values = {...item}

            delete values.question
            delete values.total
            delete values.na
            delete values.categoryId
            const maxValue = Object.values(values).reduce((first, second) => Math.max(first, second))
            
            if (maxValue === 0) return false
            
            const keysOfMaxValue = []
            Object.keys(values).map((k) => {
                if (values[k] === maxValue) keysOfMaxValue.push(k)
            })

            return keysOfMaxValue.includes(key)
        }
        const getItemsByCategory = (categoryId) => {
            return props.items.filter((statistic) => statistic.categoryId == categoryId)
        }

        const getExpandIcon = (item) => {
            return expanded.value.findIndex(expandedItem => expandedItem.categoryId === item.categoryId ) ? 'chevron-down' : 'chevron-up'
        }

        const getHighestClasses = (item, val) => {
            return isHighest(item, val) ? 'h-full w-full flex items-center justify-end px-2 text-white font-bold' : ''
        }

        return {
            expanded,
            itemsPerPage,
            footerProps,
            headersCategory,
            panelModel,
            isHighest,
            getItemsByCategory,
            getHighestClasses
        }
    }
}
</script>
