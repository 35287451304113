<template lang="pug">
    h1.text-white
        | {{title}}
</template>

<script>
export default {
    name: 'Title',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>
