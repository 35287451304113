<template lang="pug">
    .m-auto.w-full.h-full
        div(class="bg-navy-lighter shadow-md border border-navy-light rounded-md p-4 max-w-600")
            div.text-white.font-bold Copy Survey URL Link
            div.text-sm.text-off-white.mb-2 Copy the survey link and paste it on your device's web browser (Safari on iPads).
            div
                .flex.items-center.mb-2
                    v-text-field(
                        v-model="surveyURL"
                        class="white mr-3 w-350"
                        filled
                        dense hide-details)
                    v-btn(
                        color="primary"
                        depressed
                        class="primary text-white w-40 mr-0"
                        @click="copyToClipboard"
                    )
                        | {{ copyBtnText }}
            div(v-if="shortURL")
                .py-3.text-center.text-sm.text-off-white
                    | OR copy the short url for current feedback survey
                    div
            div(v-if="shortURL")
                .flex.items-center.mb-2
                    v-text-field(
                        v-model="shortURL"
                        class="white mr-3 w-350"
                        filled
                        dense hide-details)
                //.text-center
                //    v-btn(
                //        depressed
                //        type="button"
                //        class="grey mr-4"
                //        @click="closeHandler"
                //    ) Close
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

const { useActions, useGetters } = createNamespacedHelpers('')

export default {
    name: 'ModalCopyUrl',
    setup() {
        const hasCopiedToClipboard = ref(false)
        const { setModalClose } = useActions(['setModalClose'])
        const { componentData: data } = useGetters(['componentData'])

        const surveyURL = computed(() => data.value?.urls?.url)
        const shortURL = computed(() => data.value?.urls?.shortUrl)

        const closeHandler = () => {
            setModalClose()
        }

        const copyBtnText = computed(() => {
            if (hasCopiedToClipboard.value) return 'Copied!'
            return 'Copy URL'
        })

        const copyToClipboard = () => {
            navigator.clipboard.writeText(surveyURL.value)
            hasCopiedToClipboard.value = true
            setTimeout(() => {
                hasCopiedToClipboard.value = false
            }, 2000)
        }

        return {
            surveyURL,
            copyBtnText,
            shortURL,
            closeHandler,
            copyToClipboard
        }
    }
}
</script>
