import {
  MODAL_IS_VISIBLE,
  MODAL_DATA,
  MODAL_COMPONENT_NAME,
  SET_TENANT_ID,
  SET_FORM_TO_SAVE,
  SET_CONSTANTS,
  SET_STATIC_OBJECT,
  SET_LOGGED_IN_USER,
  SET_TENANT_IDS,
  SET_DASHBOARD_FILTER,
  SET_PARENT_TENANT_ID,
  SET_NPS_SCORES,
  SET_NEW_SUBSCRIPTION,
  SET_SIGN_UP_FORM,
  SET_SUBSCRIPTIONS,
  SET_HARDWARE,
  SET_SELECTED_HARDWARE,
  SET_LINE_ITEMS, SET_SIGNUP_SESSION, SET_FORM_SET, SET_IS_NEW_SUB
} from '@/store/base/mutationTypes'

export default {
  [MODAL_IS_VISIBLE](state, isVisible) {
    state.modal.isVisible = isVisible
  },
  [MODAL_DATA](state, componentData) {
    state.modal.componentData = componentData
  },
  [MODAL_COMPONENT_NAME](state, componentName) {
    state.modal.componentName = componentName
  },
  [SET_TENANT_ID](state, tenantId) {
    state.tenantId = tenantId
  },
  [SET_PARENT_TENANT_ID](state, parentTenantId) {
    state.parentTenantId = parentTenantId
  },
  [SET_DASHBOARD_FILTER](state, dashboardFilter) {
    state.dashboardFilter = dashboardFilter
  },
  [SET_TENANT_IDS](state, tenantIds) {
    state.tenantIds = tenantIds
  },
  [SET_FORM_TO_SAVE](state, form) {
    state.formToSave = form
  },
  [SET_CONSTANTS](state, constants) {
    state.constants = constants
  },
  [SET_STATIC_OBJECT](state, staticChoices) {
    state.staticChoices = staticChoices
  },
  [SET_LOGGED_IN_USER](state, user) {
    state.loggedInUser = user
  },
  [SET_NPS_SCORES](state, scores) {
    state._npsScores = {...(scores || {})}
  },
  [SET_NEW_SUBSCRIPTION](state, subscription) {
    state.newSubscription = { ...subscription }
  },
  [SET_SELECTED_HARDWARE](state, selectedHardware) {
    state.selectedHardware = { ...selectedHardware }
  },
  [SET_SIGN_UP_FORM](state, signUpForm) {
    state.signUpForm = { ...signUpForm }
  },
  [SET_SUBSCRIPTIONS](state, subcriptions) {
    state.subscriptions = [ ...subcriptions ]
  },
  [SET_HARDWARE](state, hardware) {
    state.hardware = [ ...hardware ]
  },
  [SET_LINE_ITEMS](state, lineItems) {
    state.lineItems = [ ...lineItems ]
  },
  [SET_SIGNUP_SESSION](state, signupSession) {
    state.signupSession = { ...signupSession }
  },
  [SET_FORM_SET](state, formSet) {
    state.formSet = { ...formSet }
  },
  [SET_IS_NEW_SUB](state, isNewSub) {
    state.isNewSub = isNewSub
  }
}
