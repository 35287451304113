import gql from 'graphql-tag'

const GET_TENANT_BY_SHORT_NAME = gql`query getTenantByShortName($shortName: String!) {
    tenant: form_builder_hasura_tenant(
        where: {short_name: {_eq: $shortName}}
    ) {
        tenant_id
        name
    }
}
`
const GET_TENANT_ORGANIZATION = gql`query getTenantOrganization($tenantId: uuid!) {
    organizations: form_builder_hasura_organization(
        where: {tenant_id: {_eq: $tenantId}}
    ) {
        organization_id
        tenant_id
        name
        completed_signup
    }
}
`

export {
    GET_TENANT_BY_SHORT_NAME,
    GET_TENANT_ORGANIZATION
}
