import gql from 'graphql-tag'

const GET_FORM_ANSWERS = gql`query getFormAnswers($tenantId: uuid = "", $updatedAt: timestamptz = null) {
    formAnswers: form_builder_hasura_form_answer(
        where: {
            _and: {
                tenant_id: {
                    _eq: $tenantId
                },
                updated_at: {
                    _gte: $updatedAt
                }
            }
    }) {
        answer
        form_answer_id
        tenant_id
        updated_at
        user_info
        created_at
    }
}
`

const GET_FORM_ANSWERS_BY_FORM_SET = gql`query getFormAnswers(
    $tenantId: uuid = "", $updatedAt: timestamptz = null, $formSetId: uuid = ""
) {
    formAnswers: form_builder_hasura_form_answer(
        where: {
            _and: {
                tenant_id: {
                    _eq: $tenantId
                },
                updated_at: {
                    _gte: $updatedAt
                },
                form_set_id: {
                    _eq: $formSetId
                }
            }
        }) {
        answer
        form_answer_id
        tenant_id
        updated_at
        user_info
        created_at
    }
}
`


export {
    GET_FORM_ANSWERS,
    GET_FORM_ANSWERS_BY_FORM_SET
}
