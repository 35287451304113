import gql from 'graphql-tag'

const GET_FORM_QR_CODE_PDF = gql`query getQrCodePdf($formSetId: String!, $tenantId: String!) {
    qrCode: getQrCodePdf(form_set_id: $formSetId, tenant_id: $tenantId) {
        url
    }
}
`

const GET_FORM_QR_CODE_IMG = gql`query getQrCodeImg($formSetId: String!, $tenantId: String!) {
    qrCode: getQrCodeImg(form_set_id: $formSetId, tenant_id: $tenantId) {
        url
        form_link
    }
}
`

export {
    GET_FORM_QR_CODE_PDF,
    GET_FORM_QR_CODE_IMG,
}
