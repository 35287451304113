import axios from 'axios'
// import { getInstance } from '@/auth'
import { API_KEY, REPORTS_API_HTTP } from '@/common/constants'

const axiosRequestInterceptor = async config => {
  config.baseURL = REPORTS_API_HTTP
  // Just commenting out the auth0 stuff for now
  // TODO: need to checkout why this isn't working and fix it
  
  // const token = await getInstance().getTokenSilently()
  // if (token) {
  //   config.headers['Authorization'] = `Bearer ${token}`
  // }
  if (API_KEY) config.headers['x-api-key'] = API_KEY

  // if (config.method.toUpperCase() !== 'OPTIONS') config.headers['X-API-KEY'] = API_KEY_SECRET

  return config
}

const axiosInstance = axios.create()
axiosInstance.interceptors.request.use(axiosRequestInterceptor, e => Promise.reject(e))

export {
  axiosInstance
}
