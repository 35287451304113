const required = value => !!value || 'Required'

const shortNameLength = (value) => (value?.length <= 15) || 'The maximum length is 15.'

const lowercaseAndNumber = (value) => /^[a-z0-9]*$/.test(value) || 'You can only enter lowercase characters and numbers'
export const numberOnly1Dp = (value) => !value || /^((\d|[1-9]\d+)(\.\d{1})?|\.\d{1})$/.test(value) || 'You can only enter whole numbers and/or to 1 dp'

const lowercaseDashAndNumber = (value) => /^[a-z0-9\-]*$/.test(value) || 'You can only enter lowercase characters and numbers'

const emailValidator = (value, boolCheck) => {
    const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const result = regExp.test(String(value).toLocaleLowerCase())
    if (boolCheck) return result
    return result || 'Invalid email address'
}

const passwordValidator = (value) => {
    const pattern = /(?=[A-Za-z0-9@#$%^&+!=.]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/
    if (value?.length < 8) return 'minimum 8 characters'
    console.log('result of pattern check', pattern.test(value))
    if (!pattern.test(value)) return 'must contain at least 1 Uppercase character, 1 number and 1 special character'

    return true
}

export {
    required,
    shortNameLength,
    lowercaseAndNumber,
    emailValidator,
    passwordValidator,
    lowercaseDashAndNumber
}
