<template>
    <div
        class="flex items-center px-5 small-tile"
    >
        <div
            class="p-1 w-16 h-16 rounded-full bg-transparent flex items-center justify-between"
            :class="classes"
        >
<!--            <fa-icon :icon="iconName" :class="iconClass" class="m-auto text-4xl text-black" />-->
            <Icon :icon="iconName" :class="iconClass" />
        </div>
        <div class="mx-5">
            <div class="text-2xl font-semibold text-black">{{total}}</div>
            <div class="text-grey-2 text-sm">{{title}}</div>
        </div>
    </div>
</template>

<script>
import { svgTypes } from '@/common/constants'
import Icon from '@/components/shared/partials/Icon'

export default {
    name: 'SmallTile',
    components: {Icon},
    props: {
        title: {
            type: String,
            default: ''
        },
        total: {
            type: String,
            default: ''
        },
        svgType: {
            type: String,
            default: ''
        },
        classes: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        iconClass: {
            type: String,
            default: ''
        }
    },
    computed: {
        iconName() {
            switch (this.svgType) {
                case svgTypes.NEW_SURVEYS:
                    return 'poll'
                default:
                    return this.icon
            }
        }
    }
}
</script>
