import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full w-full flex items-center justify-between px-8",attrs:{"id":"header"}},[_c('div',{staticClass:"flex items-center h-full"},[_vm._m(0),(_vm.subscriptionsLoaded)?_c('Sidebar'):_vm._e()],1),(!_vm.showOnlyLogo)?_c('div',{staticClass:"relative flex items-center"},[_c(VMenu,{attrs:{"nudge-bottom":15,"rounded":true,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('button',_vm._g(_vm._b({staticClass:"flex items-center mr-8"},'button',attrs,false),on),[_c('div',{staticClass:"mx-2 text-sm text-grey-2"},[_vm._v(" "+_vm._s(_vm.tenantName)+" ")]),(_vm.hasMultipleTenants)?_c('fa-icon',{staticClass:"text-grey-2 text-xs",attrs:{"icon":"chevron-down"}}):_vm._e()],1)]}}],null,false,1982146697)},[(_vm.hasMultipleTenants)?_c(VList,{staticClass:"border-none l-box-shadow h-350 overflow-scroll"},[_c('TenantList')],1):_vm._e()],1),_c(VMenu,{attrs:{"nudge-bottom":15,"rounded":true,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('button',_vm._g(_vm._b({staticClass:"flex items-center"},'button',attrs,false),on),[_c('div',{staticClass:"h-6 w-6 rounded-full overflow-hidden"},[_c('img',{staticClass:"h-full w-full object-cover",attrs:{"src":require("@/assets/images/empty_user.png"),"alt":"Your avatar"}})]),_c('div',{staticClass:"mx-2 text-sm text-grey-2"},[_vm._v(" "+_vm._s(_vm.fullName)+" ")]),_c('fa-icon',{staticClass:"text-text-color text-xs",attrs:{"icon":"chevron-down"}})],1)]}}],null,false,1499552767)},[_c(VList,{staticClass:"border-none py-2 w-48 rounded-md z-20 w-160 l-box-shadow dropdown-box"},[_c(VListItem,{attrs:{"link":""}},[_c('router-link',{staticClass:"block py-2 text-sm text-grey-2 hover:text-primary w-full",attrs:{"to":{ name: 'ChangePlan' }}},[_vm._v("Change Plan")])],1),_c(VListItem,{attrs:{"link":""}},[_c('router-link',{staticClass:"block py-2 text-sm text-grey-2 hover:text-primary w-full",attrs:{"to":{ name: 'ChangeHardware' }}},[_vm._v("Hardware Options")])],1),_c(VListItem,{attrs:{"link":""}},[_c('router-link',{staticClass:"block py-2 text-sm text-grey-2 hover:text-primary w-full",attrs:{"to":{ name: 'BillingHistory' }}},[_vm._v("Billing History")])],1),_c(VListItem,{attrs:{"link":""}},[_c('div',{staticClass:"block pointer-events-auto py-2 text-sm text-grey-2 hover:text-primary w-full",on:{"click":function($event){return _vm.logout(_vm.authInstance)}}},[_vm._v(" Log out ")])])],1)],1),_vm._m(1)],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo-area"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/ultimateq-tenant-logo.png"),"alt":"UltimateQ"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo-area"},[_c('a',{attrs:{"href":"https://www.ultimateq.health/user-guide","target":"_blank","title":"Open User guide"}},[_c('img',{staticClass:"ml-4 logo",attrs:{"src":require("@/assets/images/user_guide.png"),"alt":"UltimateQ"}})])])}]

export { render, staticRenderFns }