import gql from 'graphql-tag'

const GET_FORMS_FOR_TENANT = gql`
query getForm($tenantIdCompare: [form_builder_hasura_form_set_bool_exp!] = []) {
    forms: form_builder_hasura_form(
        where: {
            _or: $tenantIdCompare
        }
    ) {
        tenant_id
        form_id
        version
        title
        icon_name
        form_type_id
        survey_type
        form_type {
            form_type_id
            type
        }
    }
}
`

const GET_FORMSETS_FOR_TENANT = gql`
query getFormSets($tenantIdCompare: [form_builder_hasura_form_set_bool_exp!] = [], $tenantId: uuid!) {
    formSets: form_builder_hasura_form_set(
        where: {
            _or: $tenantIdCompare,
            published: {_eq: true}
        },
        order_by: {subscription_plan_type_id: asc, last_updated: desc}
    ) {
        tenant_id
        form_set_id
        name
        published
        offline_pdf_path
        form_set_code
        form_type {
          name
          type
          form_type_id
        }
        subscription_plan_type_id
        answers: form_answers(where: {tenant_id: {_eq: $tenantId} }, order_by: {updated_at: desc}, limit: 1) {
            form_answer_id
            updated_at
        }
        submissionsTotal: form_answers_aggregate(where: {tenant_id: {_eq: $tenantId} }) {
            aggregate {
                count
            }
        }
    }
}
`

const GET_QRCODE_FOR_FORM_SET = gql`
query getQrCode($tenantId: String!, $formSetId: String!) {
    qrCode: getQrCodeUrl(
        tenant_id: $tenantId, form_set_id: $formSetId
    ) {
        url
        code
        message
    }
}

`

export {
    GET_FORMS_FOR_TENANT,
    GET_FORMSETS_FOR_TENANT,
    GET_QRCODE_FOR_FORM_SET
}
