<template lang="pug">
    .py-4.px-8
        Title(:title="title")
        FormStatistics(
            :stats="stats"
            :form-name="formName"
            :stats-loading="isLoading"
            :form-set-id="formSetId"
            @reload-stats="reloadStats"
        )
</template>

<script>
import Title from '@/components/basic/Title'
import { ref, watch } from '@vue/composition-api'
import FormStatistics from '@/components/statistics/FormStatistics'
import { GET_STATS } from '@/graphql/queries/getStats.gql'
import { useLazyQuery } from '@vue/apollo-composable'
import camelcaseKeys from 'camelcase-keys'
import { logErrorMessages } from '@vue/apollo-util'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

const { useGetters, useActions } = createNamespacedHelpers('')

export default {
    name: 'FormDetail',
    components: { FormStatistics, Title },
    setup(_, { root }) {
        //TODO: get formId and version
        const route = root.$route
        const router = root.$router
        const { tenantId } = useGetters(['tenantId'])
        const params = route.params
        const query = route.query
        const stats = ref({})
        const formName = ref('')
        const isLoading = ref(false)
        const formSetId = ref(params.formSetId)
        const title = ref('')

        const getStatsVariables = ref({
            tenantId: tenantId,
            formSetId: params.formSetId,
        })
        // if we detect a change in tenantId we need to go back to the list of surveys, since the current list won't make sense on another tenant


        formName.value = query.n
        const {
            onResult: getStatsOnResult,
            onError: getStatsOnError,
            refetch: getStatsRefetch,
            load: getStatsLoad,
            loading
        } = useLazyQuery(GET_STATS, getStatsVariables)

        watch(loading, (newVal) => {
            isLoading.value = newVal
        })

        getStatsOnResult(({ data }) => {
            if (!data) return
            console.log('data found', data)
            const statsRes = camelcaseKeys(data?.statsResponse?.stats)
            stats.value = {...statsRes}
            console.log('stats found', statsRes)
        })

        getStatsOnError(error => {
            console.log('error that happened')
            logErrorMessages(error)
        })

        const reloadStats = (dateRange) => {
            const search = {
                ...getStatsVariables.value,
                startDate: dateRange.start,
                endDate: dateRange.end
            }
            getStatsRefetch(search)
        }

        //loading the stats
        getStatsLoad()

        watch(() => tenantId.value, (nextValue, prevValue) => {
            // we don't need to check the nextValue cause if it changes from null to a proper UUID, then we are fine
            // because it could be the user refreshing the page, so we don't mind, it's only when both values are uuids
            if (prevValue && nextValue !== prevValue) {
                isLoading.value = true
                router.push({name: 'Forms'})
            }
        })

        return {
            title,
            stats,
            formName,
            isLoading,
            formSetId,
            reloadStats
        }
    }
}
</script>
