import { getOrCreateProvider } from '@/vue-apollo'

const apolloClient = getOrCreateProvider()?.defaultClient

import camelcaseKeys from 'camelcase-keys'
import staticChoices from '@/json/staticChoices.json'
import { StripeApiService } from '@/services/stripe-api.service'
import { SignupSessionService } from '@/services/signup-session.service'
import { SubscriptionType } from '@/common/enums/subscription.type'
import { useSignupSessionStore } from '@/stores/useSignupSessionStore'
import { tenantService } from '../../services/tenant.service'

const stripeApiService = new StripeApiService()
const signupSessionService = new SignupSessionService()

export default {
    fetchStaticChoices(store) {
        const data = staticChoices
        let newChoices = {}
        const keys = Object.keys(data)
        for (let key of keys) {
            newChoices[key] = data[key].map((item) => {
                return {
                    value: item[0],
                    label: item[1],
                    text: item[1],
                }
            })
        }
        store.commit('SET_STATIC_OBJECT', newChoices)
    },
    setTenantId($store, tenantId) {
        $store.commit('SET_TENANT_ID', tenantId)
        $store.dispatch('getSignupSession')
    },
    setParentTenantId($store, parentTenantId) {
        return $store.commit('SET_PARENT_TENANT_ID', parentTenantId)
    },
    setTenantIds($store, tenantIds) {
        $store.commit('SET_TENANT_IDS', tenantIds)
    },
    setConstants($store, constants) {
        $store.commit('SET_CONSTANTS', constants)
    },
    setPermissions($store) {
        console.log('setting permissions')
    },
    setDashboardFilter($store, dashboardFilter) {
        $store.commit('SET_DASHBOARD_FILTER', dashboardFilter)
    },
    setFormSet($store, formSet) {
        $store.commit('SET_FORM_SET', formSet)
    },
    setSignUpForm($store, signUpForm) {
        $store.commit('SET_SIGN_UP_FORM', signUpForm)
    },
    async getConstants($store, {tenantId}) {
        let constants = []
        try {
            const { data } = await tenantService.getConstants(tenantId)
            constants = camelcaseKeys(data, {deep: true})
        } catch (e) {
            console.log('something happened...', e)
        }

        $store.commit('SET_CONSTANTS', constants)
    },
    setLoggedInUser($store, loggedInUser) {
        $store.commit('SET_LOGGED_IN_USER', loggedInUser)
    },
    setIsVisible($store, value) {
        $store.commit('MODAL_IS_VISIBLE', value)
    },
    setModalOpen($store, modalData) {
        $store.commit('MODAL_IS_VISIBLE', modalData?.isVisible)
        $store.commit('MODAL_DATA', modalData?.data || {})
        $store.commit('MODAL_COMPONENT_NAME', modalData?.componentName || '')
    },
    setModalClose($store) {
        $store.commit('MODAL_IS_VISIBLE', false)
        $store.commit('MODAL_DATA', {})
        $store.commit('MODAL_COMPONENT_NAME', '')
    },
    setNpsScores($store, scores) {
        $store.commit('SET_NPS_SCORES', scores)
    },
    setNewSubscription($store, subscription) {
        $store.commit('SET_NEW_SUBSCRIPTION', subscription || {})
    },
    setIsNewSub($store, isNewSub) {
        $store.commit('SET_IS_NEW_SUB', true)
    },
    setSelectedHardware($store, selectedHardware) {
        $store.commit('SET_SELECTED_HARDWARE', selectedHardware || {})
    },
    setLineItems($store, lineItems) {
        $store.commit('SET_LINE_ITEMS', lineItems || [])
    },
    async getProducts($store) {
        const { subscriptions, hardware } = await stripeApiService.getProducts()
        $store.commit('SET_SUBSCRIPTIONS', subscriptions)
        $store.commit('SET_HARDWARE', hardware)
    },
    async getSignupSession($store) {
        const signupSessionStore = useSignupSessionStore()

        const foundSignupSession = await signupSessionService.getSignupSessionByKey('tenant_id/', $store.state.tenantId)
        $store.commit('SET_SIGNUP_SESSION', { ...(foundSignupSession || {}) })
        $store.commit('SET_IS_NEW_SUB', foundSignupSession.subscriptionType !== SubscriptionType.FreeTrial)
        signupSessionStore.updateSignupSession({...foundSignupSession})
        signupSessionStore.updateSignupForm({...(foundSignupSession?.content?.form || {})})
    }
}
