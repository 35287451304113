export const useFormHelpers = () => {
    const isRcpgSurvey = (formType) => {
        // if it's a survey or formType is null, we have RCGP
        return formType?.type === 'SURVEY' || formType?.type === 'LONG_SURVEY' || !formType
    }
    const isNps = (formType) => {
        if (!formType) return false
        return formType?.type === 'NPS' ||  formType?.type === 'WIZARD'
    }

    return { isRcpgSurvey, isNps }
}
