<template lang="pug">
    div.flex.items-center.ml-8.nav-horizontal.h-full

        router-link(
            class="nav-horizontal-item flex items-center h-full"
            :class="getActiveClass('Forms')"
            :to="{name: 'Forms'}"
        )
            div.nav-text Surveys

        router-link(
            v-if="subscriptionHaveLoaded"
            class="nav-horizontal-item flex items-center h-full"
            :class="getActiveClass('Dashboard')"
            :to="{name: 'Dashboard'}"
        )
            div.nav-text(v-if="hasAtLeastLiteOption") Dashboard

            //If logged in user is on Lite, upgrade button displays.
            //link button to upgrade payment page...which prob is just a edit plan form that'll accessible from Account settings
            div.px-2.py-1.ml-4.uppercase.text-xs.text-white.rounded.font-semibold.bg-green(
                v-if="hasAtLeastLiteOption && isLite"
                @click.prevent.stop="goToChangePlan"
            )
                | Upgrade plan
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api'
import DashboardSvg from '@/assets/icons/monitor.svg'
import SurveySvg from '@/assets/icons/check-list.svg'
import { useTenantSubscription } from '@/composables/useTenantSubscription'

export default {
    name: 'SideBar',
    components: {
        DashboardSvg,
        SurveySvg,
    },
    props: {
        drawer: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit, root }) {
        const { isLite, hasAtLeastLiteOption, subscriptionHaveLoaded } = useTenantSubscription()
        const router = root.$router
        const route = ref({})
        const localDrawer = ref(false)
        const logo = ref(
            'https://assets.website-files.com/628193c332beee40f7843b3d/6281a0d0fca88d2f0c58e65e_ultimateq_logo.png'
        )

        const activeClass = 'active'
        const inactiveClass = ''

        const goToChangePlan = () => {
            router.push({ name: 'ChangePlan' })
        }

        const setDrawer = () => {
            localDrawer.value = !localDrawer.value
            emit('drawer-updated', localDrawer.value)
        }

        const closeConditional = () => {
            emit('drawer-updated', localDrawer.value)
            return localDrawer.value
        }

        const getActiveClass = (routeName) => {
            return route.value.name === routeName
                ? activeClass
                : inactiveClass
        }

        onMounted(() => {
            localDrawer.value = props.drawer
        })

        watch(
            () => props.drawer,
            (nextVal) => {
                localDrawer.value = nextVal
            }
        )
        watch(() => root.$route, (nextVal) => {
            route.value = nextVal
        }, { immediate: true })

        return {
            logo,
            isLite,
            activeClass,
            inactiveClass,
            localDrawer,
            hasAtLeastLiteOption,
            subscriptionHaveLoaded,
            setDrawer,
            closeConditional,
            getActiveClass,
            goToChangePlan
        }
    },
}
</script>
