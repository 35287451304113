<template>
    <footer class="main_footer mt-6 py-4 px-6">
        <div class="flex items-center justify-between">
            <div class="text-left">
                <span class="text-sm text-grey-2">
                    © {{ getCurrentYear }} HealthConex pty ltd
                </span>
            </div>
            <div class="text-right">
                <p class="text-xs text-grey-2">
                    SOLUTION BY
                    <a
                        href="https://ultimateq.health"
                        target="_blank"
                        class="mt-1 font-bold flex justify-end"
                    >
                        <img
                            class="w-1/3"
                            alt="HealthConex"
                            src="https://assets-global.website-files.com/648813afd6d312a42b1fcbd4/648813afd6d312a42b1fcc37_63dc3a51c938369d1e4431c8_healthconex-whitebg-logo.png"
                        />
                    </a>
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "GlobalFooter",
    computed: {
        getCurrentYear() {
            const date = new Date();
            return date.getFullYear();
        },
    },
};
</script>
