<template lang="pug">
    div
        v-tabs(v-model="selectedTab")
            v-tab(:key="0") Statistics
            v-tab(:key="1") Submissions with Comments
        v-tabs-items(v-model="selectedTab")
            v-tab-item( :key="0" )
                .flex.items-center.justify-between.my-4
                    div(class="align-middle inline-block min-w-full overflow-hidden" id="statistics-tab")
                        FormDataTable(
                            :headers="headers"
                            :items="questions"
                            :categories="categories"
                            :row-click-handler="rowClickHandler"
                            :loading=loading
                            :loading-text="'Loading... Please wait'"
                        )
            v-tab-item( :key="1" )
                Submissions.flex.items-center.justify-between.my-4(
                    :with-comments="true"
                    :tenant-id="tenantId"
                    :parent-tenant-id="parentTenantId"
                    :form-set-id="formSetId"
                )
</template>

<script>
import FormDataTable from '@/components/statistics/partials/FormDataTable'
import { ref } from '@vue/composition-api'
import Submissions from '@/components/statistics/partials/Submissions'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
const { useState } = createNamespacedHelpers('app')

export default {
    name: 'Tabs',
    components: {FormDataTable, Submissions},
    props: {
        headers: {
            type: Array,
            default: () => []
        },
        questions: {
            type: Array,
            default: () => []
        },
        categories: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        },
        formSetId: {
            type: String,
            default: ''
        },
    },
    setup() {
        const selectedTab = ref(0)
        const { tenantId, parentTenantId } = useState(['tenantId', 'parentTenantId'])


        const rowClickHandler = () => {
            console.log('row click handler')
        }

        return {
            selectedTab,
            tenantId,
            parentTenantId,
            rowClickHandler
        }
    }
}
</script>
