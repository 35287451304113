<template lang="pug">
    div
        v-list-item.pointer-events-all(
            link
            v-for="(tenant, index) in tenants" :key="`tenant-${index}`"
        )
            div(
                class="cursor-pointer px-4 py-2 text-sm hover:text-primary"
                :class="{'font-bold text-primary': isCurrentTenant(tenant.tenantId)}"
                @click="setCurrentTenant(tenant.tenantId)"
            )
                | {{ tenant.name }}

                fa-icon(v-if="isCurrentTenant(tenant.tenantId)" icon="check" class="ml-2")
</template>

<script>
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { onMounted, ref } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import camelcaseKeys from 'camelcase-keys'
import { useTenantDetailsStore } from '@/stores/useTenantDetailsStore'
import { tenantService } from '@/services/tenant.service'

const { useActions, useGetters } = createNamespacedHelpers('')

export default {
    name: 'TenantList',
    setup() {
        const tenants = ref([])
        const { tenantIds, tenantId: currentTenantId } = useGetters(['tenantIds', 'tenantId'])
        const { setTenantId } = useActions(['setTenantId'])
        const tenantDetailsStore = useTenantDetailsStore()
        const { updateTenant } = tenantDetailsStore

        const isCurrentTenant = (tenantId) => {
            return tenantId === currentTenantId.value
        }

        const setCurrentTenant = async (tenantId) => {
            await setTenantId(tenantId)
            const tenant = await tenantService.getTenant(tenantId)
            updateTenant(tenant)
        }

        onMounted(async () => {
            let tenantList = await tenantService.getTenantNames(tenantIds.value)
            tenantList = camelcaseKeys(tenantList || [], { deep: true })
            tenantList.sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()))
            tenants.value = [...tenantList]
        })

        return {
            tenants,
            isCurrentTenant,
            setCurrentTenant
        }
    }
}
</script>
