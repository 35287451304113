import gql from 'graphql-tag'

const GET_STATS_BY_FORM_SET_ID_FOR_SHORT_SURVEY = gql`
    query shortSurveyResponseAnswersForScore(
        $tenantId: uuid = "",
        $formSetId: uuid = "",
        $startDate: timestamptz,
        $endDate: timestamptz
    ) {
        scores: form_builder_hasura_survey_response_answers_for_score(
            where: {
                _and: {
                    tenant_id: { _eq: $tenantId },
                    form_set_id: { _eq: $formSetId },
                    _and: [
                        { updated_at: { _lte: $endDate } },
                        { updated_at: { _gte: $startDate } }
                    ]
                }
            }
        ) {
            category
            created_at
            updated_at
            form_answer_id
            form_set_id
            form_set_name
            label
            practice_name
            question_id
            tags
            tenant_id
            score: value
        }
    }

`

const GET_STATS_AVG_BY_CATEGORY = gql`
    query getStatsAvgByCategory(
        $formSetIdCompare: uuid_comparison_exp = {},
        $tenantIdCompare: uuid_comparison_exp = {},
        $categoryCompare: String_comparison_exp = {},
        $startDate: timestamptz,
        $endDate: timestamptz 
    ) {
        avgResults: form_builder_hasura_survey_response_avg_by_category(
            where: {
                _and: {
                    form_set_id: $formSetIdCompare,
                    tenant_id: $tenantIdCompare,
                    category: $categoryCompare,
                    _and: [
                        { day: { _lte: $endDate } },
                        { day: { _gte: $startDate } }
                    ]
                }
            }
        ) {
            category
            day
            form_set_id
            score
            tenant_id
        }
    }
`


const GET_STATS_AVG_BY_CATEGORY_FOR_PEERS = gql`
    query getStatsAvgByCategory(
        $formSetIdCompare: uuid_comparison_exp = {},
        $tenantIdCompare: uuid_comparison_exp = {},
        $categoryCompare: String_comparison_exp = {},
        $startDate: timestamptz,
        $endDate: timestamptz
    ) {
        avgResults: form_builder_hasura_survey_response_avg_by_category_for_peers(
            where: {
                _and: {
                    form_set_id: $formSetIdCompare,
                    tenant_id: $tenantIdCompare,
                    category: $categoryCompare,
                    _and: [
                        { day: { _lte: $endDate } },
                        { day: { _gte: $startDate } }
                    ]
                }
            }
        ) {
            category
            day
            form_set_id
            score
            tenant_id
        }
    }
`

const GET_TENANT_STATS_AVG_BY_CATEGORY = gql`
    query getStatsAvgByCategory(
        $formSetIdCompare: uuid_comparison_exp = {},
        $tenantIdCompare: uuid_comparison_exp = {},
        $startDate: timestamptz,
        $endDate: timestamptz
    ) {
        avgResults: form_builder_hasura_survey_response_avg_by_category(
            where: {
                _and: {
                    form_set_id: $formSetIdCompare,
                    tenant_id: $tenantIdCompare,
                    _and: [
                        { day: { _lte: $endDate } },
                        { day: { _gte: $startDate } }
                    ]
                }
            }
        ) {
            category
            day
            form_set_id
            score
            tenant_id
        }
    }
`

const GET_PEER_STATS_AVG_BY_CATEGORY = gql`
    query getStatsAvgByCategory(
        $formSetIdCompare: uuid_comparison_exp = {},
        $tenantIdCompare: uuid_comparison_exp = {},
        $startDate: timestamptz,
        $endDate: timestamptz
    ) {
        avgResults: form_builder_hasura_survey_response_avg_by_category_for_peers(
            where: {
                _and: {
                    form_set_id: $formSetIdCompare,
                    tenant_id: $tenantIdCompare,
                    _and: [
                        { day: { _lte: $endDate } },
                        { day: { _gte: $startDate } }
                    ]
                }
            }
        ) {
            category
            day
            form_set_id
            score
            tenant_id
        }
    }
`



const GET_SURVEY_STATS_BY_QUESTION = gql`
    query getSurveyStatsResponses(
        $formSetIdCompare: uuid_comparison_exp = {},
        $tenantIdCompare: uuid_comparison_exp = {},
        $categoryCompare: String_comparison_exp = {},
        $startDate: timestamptz,
        $endDate: timestamptz
    ) {
        scores: form_builder_hasura_survey_stats_racgp_by_category_day_segment(
            where: {
                _and: {
                    form_set_id: $formSetIdCompare,
                    tenant_id: $tenantIdCompare,
                    category: $categoryCompare,
                    _and: [
                        { day: { _lte: $endDate } },
                        { day: { _gte: $startDate } }
                    ]
                }
            }
        ) {
            tenant_id
            form_set_id
            score
            survey_count
            category
            day
            created_at: day
            updated_at: day
        }
    }
`

const GET_SURVEYS_COMPLETED_STATS_RESPONSE = gql`
    query getSurveyStatsResponses(
        $formSetIdCompare: uuid_comparison_exp = {},
        $tenantIdCompare: uuid_comparison_exp = {},
        $categoryCompare: String_comparison_exp = {},
        $startDate: timestamptz,
        $endDate: timestamptz
    ) {
        scores: form_builder_hasura_survey_stats_responses(
            where: {
                _and: {
                    form_set_id: $formSetIdCompare,
                    tenant_id: $tenantIdCompare,
                    category: $categoryCompare,
                    _and: [
                        { day: { _lte: $endDate } },
                        { day: { _gte: $startDate } }
                    ]
                }
            }
        ) {
            tenant_id
            form_set_id
            short_survey
            long_survey
            day
            created_at
            updated_at
            category
        }
    }
`
const GET_SURVEYS_RACGP_COMPLETED_STATS_RESPONSE = gql`
    query getSurveyStatsRacgpResponses(
        $formSetIdCompare: uuid_comparison_exp = {},
        $tenantIdCompare: uuid_comparison_exp = {},
        $categoryCompare: String_comparison_exp = {},
        $startDate: timestamptz,
        $endDate: timestamptz
    ) {
        scores: form_builder_hasura_survey_stats_racgp_by_category_day_segment(
            where: {
                _and: {
                    form_set_id: $formSetIdCompare,
                    tenant_id: $tenantIdCompare,
                    category: $categoryCompare,
                    _and: [
                        { day: { _lte: $endDate } },
                        { day: { _gte: $startDate } }
                    ]
                }
            }
        ) {
            tenant_id
            form_set_id
            day
            category
            score
        }
    }
`

const GET_SURVEYS_RACGP_TOTAL_SURVEY_COUNT = gql`
    query getSurveyStatsRacgpTotalSurveyCount(
        $formSetIdCompare: uuid_comparison_exp = {},
        $tenantIdCompare: uuid_comparison_exp = {},
        $startDate: timestamptz,
        $endDate: timestamptz
    ) {
        totalSurveys: form_builder_hasura_survey_stats_racgp_total_count(
            where: {
                _and: {
                    form_set_id: $formSetIdCompare,
                    tenant_id: $tenantIdCompare,
                    _and: [
                        { day: { _lte: $endDate } },
                        { day: { _gte: $startDate } }
                    ]
                }
            }
        ) {
            tenant_id
            form_set_id
            day
            survey_count
        }
    }
`

const GET_SURVEYS_STATS_OVERALL_AVG_COUNT = gql`
    query getSurveyStatsResponses(
        $formSetIdCompare: uuid_comparison_exp = {},
        $tenantId: uuid = "",
        $startDate: timestamptz,
        $endDate: timestamptz
    ) {
        avgResults: form_builder_hasura_survey_stats_overall_avg_count(
            where: {
                _and: {
                    form_set_id: $formSetIdCompare,
                    tenant_id: { _eq: $tenantId },
                    _and: [
                        { day: { _lte: $endDate } },
                        { day: { _gte: $startDate } }
                    ]
                }
            }
        ) {
            form_set_id
            surveys
            updated_at: day
        }
    }
`

const GET_LONG_SURVEYS_ANSWERS = gql`
    query getLongSurveysAnswers(
        $formSetId: uuid = "",
        $tenantId: uuid = "",
        $startDate: timestamptz,
        $endDate: timestamptz
    ) {
        surveys: form_builder_hasura_survey_response_long_survey_answers(
            where: {
                form_set_id: { _eq: $formSetId },
                tenant_id: { _eq: $tenantId },
                _and: [
                    { updated_at: { _lte: $endDate } },
                    { updated_at: { _gte: $startDate } }
                ]
            }
        ) {
            form_set_id
            tenant_id
            form_answer_id
            updated_at
        }
    }
`

const GET_NPS_SCORE = gql`
    query getNpsScore(
        $formSetId: String! = "",
        $tenantId: String! = "",
        $startDate: String,
        $endDate: String
    ) {
        nps_scores: getNpsScore(form_set_id: $formSetId, tenant_id: $tenantId, start_date: $startDate, end_date: $endDate) {
            stats
        }
    }
`

const GET_PSS_SCORE_STATS = gql`
    query getPssStatsResponses($formSetIdCompare: uuid_comparison_exp = {}, $tenantIdCompare: uuid_comparison_exp = {}, $startDate: date, $endDate: date) {
        scores: form_builder_reporting_pss_stats(where: {_and: {form_set_id: $formSetIdCompare, tenant_id: $tenantIdCompare, _and: [{updated_at: {_lte: $endDate}}, {updated_at: {_gte: $startDate}}]}}) {
            tenant_id
            form_set_id
            updated_at
            __typename
            count_value
            pss_stats_id
            question
            ticked
        }
    }
`
export const GET_PSS_COUNTS = gql`
    query getPssCounts($formSetIdCompare: uuid_comparison_exp = {}, $tenantIdCompare: uuid_comparison_exp = {}, $startDate: String, $endDate: String) {
        stats: form_builder_reporting_pss_counts(where: {_and: {form_set_id: $formSetIdCompare, tenant_id: $tenantIdCompare, _and: [{month: {_lte: $endDate}}, {month: {_gte: $startDate}}]}}) {
            tenant_id
            form_set_id
            month
            total_count
            total_score
            promoters
            detractors
            count_per_score
            pss_counts_id
        }
    }

`

export {
    GET_NPS_SCORE,
    GET_STATS_BY_FORM_SET_ID_FOR_SHORT_SURVEY,
    GET_STATS_AVG_BY_CATEGORY,
    GET_TENANT_STATS_AVG_BY_CATEGORY,
    GET_PEER_STATS_AVG_BY_CATEGORY,
    GET_SURVEY_STATS_BY_QUESTION,
    GET_SURVEYS_COMPLETED_STATS_RESPONSE,
    GET_SURVEYS_STATS_OVERALL_AVG_COUNT,
    GET_LONG_SURVEYS_ANSWERS,
    GET_STATS_AVG_BY_CATEGORY_FOR_PEERS,
    GET_SURVEYS_RACGP_COMPLETED_STATS_RESPONSE,
    GET_PSS_SCORE_STATS,
    GET_SURVEYS_RACGP_TOTAL_SURVEY_COUNT
}
