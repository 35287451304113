<template>
    <component :is="getIcon()" :class="highlightIcon" />
</template>

<script>
import MaleSvg from '@/assets/icons/male.svg'
import FemaleSvg from '@/assets/icons/female.svg'
import ClipboardSvg from '@/assets/icons/clipboard.svg'
import BlankSvg from '@/assets/icons/page-blank.svg'

export default {
    name: 'Icon',
    components: {
        MaleSvg,
        FemaleSvg,
        ClipboardSvg,
        BlankSvg
    },
    props: {
        icon: {
            type: String,
            default: ''
        },
        svgColour: {
            type: String,
            default: ''
        },
    },
    methods: {
        getIcon() {
            switch(this.icon.toUpperCase()) {
                case 'MALE':
                    return 'MaleSvg'
                case 'FEMALE':
                    return 'FemaleSvg'
                case 'CLIPBOARD':
                    return 'ClipboardSvg'
                case 'BLANK':
                    return 'BlankSvg'
                default:
                    return 'ClipboardSvg'
            }
        }
    },
    computed: {
        highlightIcon() {
            return this.svgColour
        },
    },
}
</script>
