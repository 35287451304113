<template lang="pug">
    .m-auto.w-full.h-full
        h1(class="text-black mb-2 text-xl lg:text-2xl text-center") Survey Answers

        .px-4.h-full(v-if="!iFrameLoading")
            .h-full.float-left.absolute.flex.flex-col.cursor-pointer(
                v-if="!hideButtons"
                class="top-0 left-0 ml-4"
                :class="{'disabled pointer-events-none': isDisabled('left')}"
            )
                fa-icon(
                    icon="chevron-left"
                    class="mr-1 text-2xl m-auto"
                    @click="goToPrevious"
                )

            .text-center(class="min-h-3/4")
                vue-friendly-iframe(
                    v-if="!iFrameLoading"
                    ref="surveyCloudForm"
                    :class-name="'flex flex-col w-full min-h-3/4 form-iframe'"
                    :src="iFrameUrl"
                    name="surveyCloudForm"
                )
            .h-full.float-right.absolute.flex.flex-col.cursor-pointer(
                v-if="!hideButtons"
                class="top-0 right-0 mr-4"
                :class="{'disabled pointer-events-none': isDisabled('right')}"
            )
                fa-icon(
                    icon="chevron-right"
                    class="ml-1 text-2xl m-auto"
                    @click="goToNext"
                )

            .flex.flex-row.justify-center.mt-4
                v-btn(
                    depressed
                    class="mr-4 w-40"
                    @click="closeHandler"
                ) Close
        .flex.items-center.justify-center.h-20.w-full(v-else)
            Loader(class="w-12 h-12")
</template>

<script>
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { ref, computed } from '@vue/composition-api'
import { IFRAME_CHILD } from '@/common/constants'
import Loader from '@/assets/loader.svg'

const { useActions, useGetters } = createNamespacedHelpers('')

export default {
    name: 'ModalViewSurvey',
    components: { Loader },
    setup() {
        const { setModalClose } = useActions(['setModalClose'])
        const { componentData } = useGetters(['componentData'])
        const answer = ref({})
        const iFrameLoaded = ref(false)
        const iFrameLoading = ref(true)
        const iFrameUrl = ref('')
        const defaultIdx = ref(1)
        const numViewableForms = ref(0)
        const currentFormIdx = ref(0)
        const tenantId = ref('')
        const formSetId = ref('')
        const formAnswerId = ref('')

        const closeHandler = () => {
            setModalClose()
        }

        answer.value = componentData?.value.answer
        const setUrl = () => {
            iFrameUrl.value = `${IFRAME_CHILD}/DynamicForm?tenant=${tenantId.value}&fsi=${formSetId.value}&fai=${formAnswerId.value}&ext=t&ro=t&faidx=${currentFormIdx.value}&nh=t`
        }

        const isDisabled = (button) => {
            if (button === 'left') return currentFormIdx.value <= defaultIdx.value
            if (button === 'right') return currentFormIdx.value >= numViewableForms.value - 1
        }

        const goToNext = () => {
            currentFormIdx.value++
            setUrl()
        }

        const goToPrevious = () => {
            currentFormIdx.value--
            setUrl()
        }

        const hideButtons = computed(() => {
            return defaultIdx.value === numViewableForms.value - 1
        })

        setTimeout(() => {
            const answerList = answer.value?.answer
            tenantId.value = answer.value?.tenantId
            formAnswerId.value = answer.value?.formAnswerId
            formSetId.value = answer.value?.formSetId
            numViewableForms.value = answerList.length
            if (numViewableForms.value > 1) currentFormIdx.value = 1
            setUrl(tenantId, formSetId, formAnswerId)
            console.log('iframe url that we found', iFrameUrl.value)
            iFrameLoading.value = false
        }, 1000)

        return {
            iFrameUrl,
            iFrameLoading,
            hideButtons,
            closeHandler,
            isDisabled,
            goToNext,
            goToPrevious
        }
    }
}
</script>
