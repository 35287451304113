import gql from 'graphql-tag'

const GET_STATS = gql`query getStats($formSetId: String!, $tenantId: String!, $startDate: String, $endDate: String) {
    statsResponse: getStats(formSetId: $formSetId, tenantId: $tenantId, startDate: $startDate, endDate: $endDate) {
        stats
        message
        code
    }
}
`

export {
    GET_STATS
}
