import Vue from 'vue'
import createAuth0Client from '@auth0/auth0-spa-js'
import loadUserInfo from './userInfoLoader'
import camelcaseKeys from 'camelcase-keys'
import { CS_AUTH0_CLAIM_ID } from '@/common/constants'
import store from '@/store'
import { useTenantDetailsStore } from '@/stores/useTenantDetailsStore'

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
    window.history.replaceState({}, document.title, window.location.pathname)

let instance

/** Returns the current instance of the SDK */
const getInstance = () => instance

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
const useAuth0 = ({ onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, redirectUri = window.location.origin, ...options }) => {
    if (instance) return instance
    
    // The 'instance' is simply a Vue object
    instance = new Vue({
        data() {
            return {
                initialized: false,
                onRedirectCallback: null,
                redirectUri: null,
                options: null,
                loading: true,
                isAuthenticated: false,
                isUserInfoLoaded: false,
                user: {},
                auth0Client: null,
                popupOpen: false,
                error: null,
                tenantId: null,
                tenantIds: []
            }
        },
        /** Use this lifecycle method to instantiate the SDK client */
        async created() {
            this.onRedirectCallback = onRedirectCallback
            this.redirectUri = redirectUri
            this.options = options

            await this.initiateLoginProcess()
        },
        methods: {
            async createAuth0Client() {
                if (!this.auth0Client) {
                    const scopes = process.env.VUE_APP_AUTH0_SCOPES?.split(',').join(' ') || []

                    try {
                        // Create a new instance of the SDK client using members of the given options object
                        this.auth0Client = await createAuth0Client({
                            ...this.options,
                            domain: this.options.domain,
                            client_id: this.options.clientId,
                            audience: this.options.audience,
                            redirect_uri: this.redirectUri,
                            organization: this.options.organization,
                            advancedOptions: {
                                defaultScope: scopes // change the scopes that are applied to every authz request. **Note**: `openid` is always specified regardless of this setting
                            },
                        })
                    } catch (e) {
                        this.error = e
                        return false
                    }
                }
            },
            async initiateLoginProcess(options) {
                if (this.initialized) return true

                await this.createAuth0Client()
                let { state, code } = options || {state: null, code: null}
                if (!state) state = window.location.search.includes('state=')
                if (!code) code = window.location.search.includes('code=')
                try {
                    // If the user is returning to the app after authentication..
                    if (state && code) {
                        // handle the redirect and retrieve tokens
                        const { appState } = await this.auth0Client.handleRedirectCallback()

                        // Notify subscribers that the redirect callback has happened, passing the appState
                        // (useful for retrieving any pre-authentication state)
                        this.onRedirectCallback(appState)
                    }
                } catch (e) {
                    this.error = e
                } finally {
                    // Initialize our internal authentication state
                    this.isAuthenticated = await this.auth0Client.isAuthenticated()
                    this.user = camelcaseKeys(await this.auth0Client.getUser() || {}, {
                        exclude: [CS_AUTH0_CLAIM_ID]
                    })
                    this.tenantId = this.user[CS_AUTH0_CLAIM_ID]?.tenantId
                    this.tenantIds = this.user[CS_AUTH0_CLAIM_ID]?.tenantIds
                    this.isAuthenticated && await loadUserInfo(this)
                    this.loading = false
                }

                const { getTenant } = useTenantDetailsStore()
                await getTenant(this.tenantId)
                await store.dispatch('setTenantId', this.tenantId)
                await store.dispatch('setTenantIds', this.tenantIds)
                await store.dispatch('getProducts')

                this.initialized = true
                // return this.initialized
            },
            /** Authenticates the user using a popup window */
            async loginWithPopup(o) {
                this.popupOpen = true
                
                try {
                    await this.auth0Client.loginWithPopup(o)
                } catch (e) {
                    // eslint-disable-next-line
                    console.error(e)
                } finally {
                    this.popupOpen = false
                }
                
                this.user = camelcaseKeys(await this.auth0Client.getUser() || {}, {
                    exclude: [CS_AUTH0_CLAIM_ID]
                })
                this.tenantId = this.user[CS_AUTH0_CLAIM_ID]?.tenantId
                this.tenantIds = this.user[CS_AUTH0_CLAIM_ID]?.tenantIds
                this.isAuthenticated && await loadUserInfo(this)
                const { getTenant } = useTenantDetailsStore()
                await getTenant(this.tenantId)
                await store.dispatch('setTenantId', this.tenantId)
                await store.dispatch('setTenantIds', this.tenantIds)
                await store.dispatch('getProducts')

                this.isAuthenticated = true
                // await loadUserInfo(this)
            },
            /** Handles the callback when logging in using a redirect */
            async handleRedirectCallback() {
                this.loading = true
                try {
                    await this.auth0Client.handleRedirectCallback()
                    this.user = camelcaseKeys(await this.auth0Client.getUser() || {}, {
                        exclude: [CS_AUTH0_CLAIM_ID]
                    })
                    this.tenantId = this.user[CS_AUTH0_CLAIM_ID]?.tenantId
                    this.tenantIds = this.user[CS_AUTH0_CLAIM_ID]?.tenantIds
                    this.isAuthenticated && await loadUserInfo(this)
                    const { getTenant } = useTenantDetailsStore()
                    await getTenant(this.tenantId)
                    await store.dispatch('setTenantId', this.tenantId)
                    await store.dispatch('setTenantIds', this.tenantIds)
                    await store.dispatch('getProducts')

                    this.isAuthenticated = true
                    // await loadUserInfo(this)
                } catch (e) {
                    this.error = e
                } finally {
                    this.loading = false
                }
            },
            /** Authenticates the user using the redirect method */
            loginWithRedirect(o) {
                return this.auth0Client.loginWithRedirect(o)
            },
            /** Returns all the claims present in the ID token */
            getIdTokenClaims(o) {
                return this.auth0Client.getIdTokenClaims(o)
            },
            /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
            getTokenSilently(o) {
                return this.auth0Client.getTokenSilently(o)
            },
            /** Gets the access token using a popup window */
            getTokenWithPopup(o) {
                return this.auth0Client.getTokenWithPopup(o)
            },
            /** Logs the user out and removes their session on the authorization server */
            logout(o) {
                return this.auth0Client.logout(o)
            },
            isLoggedIn() {
                return this.isAuthenticated && this.isUserInfoLoaded
            }
        }
    })
    
    return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
const Auth0Plugin = {
    install(Vue, options) {
        Vue.prototype.$auth = useAuth0(options)
    }
}

export {
    Auth0Plugin,
    useAuth0,
    getInstance
}
