// Modal state mutations
export const MODAL_IS_VISIBLE = 'MODAL_IS_VISIBLE'
export const MODAL_DATA = 'MODAL_DATA'
export const MODAL_COMPONENT_NAME = 'MODAL_COMPONENT_NAME'

export const SET_TENANT_ID = 'SET_TENANT_ID'
export const SET_TENANT_IDS = 'SET_TENANT_IDS'
export const SET_FORM_TO_SAVE = 'SET_FORM_TO_SAVE'
export const SET_CONSTANTS = 'SET_CONSTANTS'
export const SET_STATIC_OBJECT = 'SET_STATIC_OBJECT'

export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER'

export const SET_DASHBOARD_FILTER = 'SET_DASHBOARD_FILTER'
export const SET_PARENT_TENANT_ID = 'SET_PARENT_TENANT_ID'
export const SET_NPS_SCORES = 'SET_NPS_SCORES'

export const SET_NEW_SUBSCRIPTION = 'SET_NEW_SUBSCRIPTION'
export const SET_SELECTED_HARDWARE = 'SET_SELECTED_HARDWARE'
export const SET_SIGN_UP_FORM = 'SET_SIGN_UP_FORM'
export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS'
export const SET_HARDWARE = 'SET_HARDWARE'
export const SET_LINE_ITEMS = 'SET_LINE_ITEMS'
export const SET_SIGNUP_SESSION = 'SET_SIGNUP_SESSION'
export const SET_FORM_SET = 'SET_FORM_SET'
export const SET_IS_NEW_SUB = 'SET_IS_NEW_SUB'
