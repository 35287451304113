<template lang="pug">
    .m-auto.w-full.h-full
        img(src="@/assets/images/QPA_standard_logo.png" class="m-auto w-150 mb-2")
        h1.text-black.text-center Practice Invite

        v-form(
            ref="practiceInviteForm"
            v-model="isValid"
            @submit.prevent="formSubmitHandler"
        )
            v-textarea(
                v-model="instructionText"
                class="mx-5 mt-8"
                label="Instruction"
                :rules="[required]"
            )
            v-text-field(
                v-model="email"
                class="mx-5 mt-2"
                label="Email"
                :rules="[required]"
            )
            .text-center
                v-btn(
                    depressed
                    class="mr-4 w-40"
                    @click="closeHandler"
                ) Close
                v-btn(
                    type="submit"
                    class="mr-4 w-40"
                    color="success"
                    :disabled="invitePracticeLoading"
                )
                    fa-icon(v-if="invitePracticeLoading" icon="spinner" spin class="mr-2 text-primary-light")
                    | Send
</template>

<script>
import { ref } from '@vue/composition-api'
import { required } from '@/modules/formRules'
import { INVITE_PRACTICE } from '@/graphql/mutations/invitePractice.gql'
import { useMutation } from '@vue/apollo-composable'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

const { useActions } = createNamespacedHelpers('')

export default {
    name: 'ModalSendSurveyInvite',
    setup(_, { root }) {
        const instructionText = ref('')
        const email = ref('')
        const isValid = ref(false)
        const practiceInviteForm = ref(null)
        const { setModalClose } = useActions(['setModalClose'])

        const formSubmitHandler = () => {
            isValid.value = practiceInviteForm.value.validate()
            if (!isValid.value) return

            invitePractice()
        }

        const {
            onError: onInivitePracticeError,
            mutate: invitePractice,
            onDone: onPracticeInvited,
            loading: invitePracticeLoading
        } = useMutation(INVITE_PRACTICE, () => {
                return {
                    variables: {
                        instruction: instructionText.value,
                        email: email.value
                    }
                }
            }
        )
        onPracticeInvited((error) => {
            console.log(error, '++++++++')
            // do something else if it errors
            closeHandler()
        })

        const closeHandler = () => {
            setModalClose()
        }

        return {
            instructionText,
            email,
            isValid,
            practiceInviteForm,
            invitePracticeLoading,
            required,
            formSubmitHandler,
            closeHandler
        }
    }
}
</script>
