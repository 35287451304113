<template>
    <v-app class="w-full">
        <div
            class="bg-navy browserTooSmallMsg text-off-white l-box-shadow rounded"
        >
            <img
                src="@/assets/images/image_browserresize.png"
                alt="Resize browser"
                class="block m-auto resizeBrowserImage"
            />
            <h1 class="text-xl mb-3 text-center">
                Your browser or device is too small
            </h1>
            <div class="text-center">
                This app works best on tablet devices (at minimum Ipad Mini) and
                desktops. Change device/or resize your browser to be at least
                768px wide to get back in.
            </div>
        </div>
        <div class="browserTooSmallOverlay"></div>
        <!--
        <Sidebar v-if="!hideHeaderAndFooter" :drawer="drawer" @drawer-updated="updateDrawer" />-->
        <v-main class="h-full">
            <div class="flex flex-col h-full">
                <div v-if="!hideHeaderAndFooter">
                    <Header @drawer-updated="updateDrawer" />
                </div>
                <router-view />
                <GlobalFooter v-if="!hideHeaderAndFooter" class="mt-auto" />
            </div>
        </v-main>
        <component :is="modalActive" class="absolute" />
    </v-app>
</template>

<script>
import { computed, onBeforeMount, ref } from '@vue/composition-api'
import { useRoute } from '@/utils/migrate.routervue3'
import Header from '@/components/Header'
import GlobalFooter from '@/components/shared/GlobalFooter'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import Modal from '@/components/shared/Modal'
import store from '@/store'

const { useState } = createNamespacedHelpers('app')
const { useActions } = createNamespacedHelpers('')
const defaultLayout = 'default'
const tenantId = '0e21b047-1d5c-4512-bc8e-69b5076e3386'

export default {
    components: { Header, GlobalFooter, Modal },
    metaInfo: {
        //TODO: change to dynamic when the values are coming from a tenant
        title: 'UltimateQ',
        meta: [
            { charset: 'utf-8' },
            {
                name: 'viewport',
                content:
                    'user-scalable=0, width=device-width, height=device-height, initial-scale=1, maximum-scale=1.0, minimum-scale=1.0',
            },
        ],
    },
    setup() {
        const state = useState(['modal'])
        const isVisible = ref(state?.modal?.isVisible)
        const drawer = ref(false)
        const updateDrawer = (val) => {
            drawer.value = val
        }
        const { getConstants, fetchStaticChoices } = useActions([ 'getConstants', 'fetchStaticChoices'])

        const currentRoute = useRoute()
        const hideHeaderAndFooter = computed(() => {
            return currentRoute?.meta?.layout
        })

        const modalActive = computed(() => {
            return isVisible ? 'Modal' : ''
        })

        onBeforeMount(async () => {
            // setTenantId({ tenantId })
            await getConstants({ tenantId })
            await fetchStaticChoices()
        })

        return {
            drawer,
            hideHeaderAndFooter,
            modalActive,
            updateDrawer,
        }
    },
}
</script>
