const downloadFileHandler = (url, name = 'blank') => {
    const fileLink = document.createElement('a')
    fileLink.href = url
    fileLink.setAttribute('target', '_blank')
    fileLink.setAttribute('download', name)
    document.body.appendChild(fileLink)
    fileLink.click()
}

const downloadBlobFileHandler = (blobFile, name = 'blank.pdf') => {
    const fileLink = document.createElement('a')
    const file = new Blob([blobFile], {type: 'application/pdf'})
    fileLink.href = URL.createObjectURL(file)
    fileLink.setAttribute('target', '_blank')
    fileLink.setAttribute('download', name)
    document.body.appendChild(fileLink)
    fileLink.click()
}


const goToUrl = (url, openInNewTab) => {
    if (openInNewTab) {
        window.open(url, '_blank')
    } else {
        window.location = url
    }
}

export {
    downloadFileHandler,
    downloadBlobFileHandler,
    goToUrl
}
