import { defineStore } from 'pinia'
import { computed, ref } from '@vue/composition-api'

export const useSignupSessionStore = defineStore('subscriptions', () => {
    const signupSession = ref({})
    const signupForm = ref({})
    const signupSessionSub = ref({})
    const signupSessionLineItems = ref([])
    const _signupSessionErrorMessage = ref('')
    const _signupSessionLoading = ref(false)

    const signupSessionId = computed(() => signupSession.value?.signupSessionId)

    const computedSignupForm = computed(() => signupForm.value)
    const signupSessionErrorMessage = computed(() => _signupSessionErrorMessage.value)
    const signupSessionLoading = computed(() => _signupSessionLoading.value)

    const updateSignupSession = (newSignupSession) => {
        signupSession.value = { ...newSignupSession }
    }
    const updateSignupForm = (newSignupForm) => {
        signupForm.value = { ...newSignupForm }
    }
    const updateSignupSessionSub = (newSignupSessionSub) => {
        signupSessionSub.value = { ...newSignupSessionSub }
    }

    const updateSignupSessionErrorMessage = (message) => {
        _signupSessionErrorMessage.value = message
    }

    const updateSignupSessionLineItems = (lineItems) => {
        signupSessionLineItems.value = [...(lineItems || [])]
    }

    const updateSignupSessionLoading = (value) => {
        _signupSessionLoading.value = value
    }

    return {
        signupSession,
        signupForm,
        signupSessionId,
        signupSessionSub,
        signupSessionLineItems,
        computedSignupForm,
        signupSessionErrorMessage,
        signupSessionLoading,
        updateSignupSession,
        updateSignupForm,
        updateSignupSessionSub,
        updateSignupSessionLineItems,
        updateSignupSessionErrorMessage,
        updateSignupSessionLoading
    }
})
