import { computed } from '@vue/composition-api'
import { SubscriptionType } from '@/common/enums/subscription.type'

export default {
    tenantId: (state) => state.tenantId,
    parentTenantId: (state) => state.parentTenantId,
    tenantIds: (state) => state.tenantIds,
    isVisible: (state) => state.modal.isVisible,
    componentName: (state) => state.modal.componentName,
    componentData: (state) => state.modal.componentData,
    dashboardFilter: (state) => state.dashboardFilter,
    npsScores: (state) => state._npsScores,
    newSubscription: (state) => state.newSubscription,
    signUpForm: (state) => state.signUpForm,
    subscriptions: (state) => state.subscriptions,
    subscriptionsLoaded: (state) => state.subscriptions.length > 0,
    hardware: (state) => state.hardware,
    selectedHardware: (state) => state.selectedHardware,
    lineItems: (state) => state.lineItems,
    signupSession: (state) => state.signupSession,
    isFreeTrial: (state) => state.signupSession?.subscriptionType === SubscriptionType.FreeTrial,
    formSet: (state) => state.formSet,
    isNewSub: (state) => state.isNewSub,
    subscriptionType: (state) => {
        const priceId = state.signupSession.content?.lineItems?.at(0)?.price
        if (!priceId) return ''
        const foundSubscription = state.subscriptions.find((s) => s?.price?.id === priceId)
        if (!foundSubscription) return ''
        return foundSubscription?.metadata?.type || ''
    }
}
