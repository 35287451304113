import Vue from 'vue'
import VueRouter from 'vue-router'
import Forms from '@/views/Forms.vue'
import Blank from '@/views/Blank.vue'
import FormDetail from '@/views/FormDetail'
import { authGuard } from '@/auth'
import { PROJECT_ENV } from '@/common/constants'

Vue.use(VueRouter)

let routes = [
    {
        path: '/',
        redirect: '/forms'
    },
    {
        path: '/login',
        name: 'Login',
        redirect: '/'
    },
    // {
    //     path: '/',
    //     name: 'Login',
    //     component: Login,
    //     meta: { layout: 'empty' },
    //     beforeEnter: authGuard,
    // },
    {
        path: '/signup-old',
        name: 'Signup',
        component: () => import(/* webpackChunkName: "Signup" */ '@/views/Signup.vue'),
        meta: { layout: 'empty' },
    },
    {
        path:'/signup',
        name: 'SignUpForFree',
        component: () => import(/* webpackChunkName: "SignupForFree" */ '@/views/SignupForFree.vue'),
        meta: {layout: 'empty'}
    },
    {
        path:'/signup-redirect',
        name: 'SignUpWithRedirect',
        component: () => import(/* webpackChunkName: "SignupWithRedirect" */ '@/views/SignupWithRedirect.vue'),
        meta: {layout: 'empty'}
    },
    {
        path:'/signup-success',
        name: 'SuccessfulSignup',
        component: () => import(/* webpackChunkName: "SuccessfulSignup" */ '@/views/SuccessfulSignup.vue'),
        meta: {layout: 'empty'}
    },
    {
        path:'/signup-cancel',
        name: 'SignUpWithRedirect',
        component: () => import(/* webpackChunkName: "SignupWithRedirect" */ '@/views/SignupWithRedirect.vue'),
        meta: {layout: 'empty'}
    },
    {
        path:'/organisation/edit',
        name: 'EditOrganisation',
        component: () => import(/* webpackChunkName: "EditOrganisationView" */ '@/views/EditOrganisationView.vue'),
        beforeEnter: authGuard,
    },
    {
        path: '/forms',
        name: 'Forms',
        component: Forms,
        beforeEnter: authGuard,
    },
    {
        path: '/forms/:formSetId',
        name: 'FormDetail',
        component: FormDetail,
        beforeEnter: authGuard,
    },
    {
        path: '/account-settings',
        name: 'AccountSettings',
        component: () => import(/* webpackChunkName: "AccountSettings" */ '@/views/AccountSettings.vue'),
        beforeEnter: authGuard,
    },
    {
        path: '/profile-settings',
        name: 'ProfileSettings',
        component: () => import(/* webpackChunkName: "ProfileSettings" */ '@/views/ProfileSettings.vue'),
        beforeEnter: authGuard,
    },
    {
        path: '/billing-history',
        name: 'BillingHistory',
        component: () => import(/* webpackChunkName: "Billing" */ '@/views/BillingHistory.vue'),
        beforeEnter: authGuard,
    },
    {
        path: '/change-plan',
        name: 'ChangePlan',
        component: () => import(/* webpackChunkName: "Billing" */ '@/views/ChangePlan.vue'),
        beforeEnter: authGuard,
    },
    {
        path: '/hardware',
        name: 'ChangeHardware',
        component: () => import(/* webpackChunkName: "Billing" */ '@/views/ChangeHardware.vue'),
        beforeEnter: authGuard,
    },
    {
        path: '/blank',
        name: 'Blank',
        component: Blank,
        beforeEnter: authGuard,
    },
    // {
    //     path: '/user-management',
    //     name: 'UserList',
    //     component: () => import(/* webpackChunkName: "user-list" */ '@/components/user-management/UserList'),
    //     beforeEnter: authGuard,
    // },
    // {
    //     path: '/user-management/:userId',
    //     name: 'UserDetails',
    //     component: () => import(/* webpackChunkName: "user-details" */ '@/components/user-management/UserDetails'),
    //     beforeEnter: authGuard,
    // },
    {
        path: '/:shortName/invite',
        name: 'PracticeInvite',
        component: () => import(/* webpackChunkName: "Signup" */ '@/views/PracticeInvite.vue'),
    },
    {
        path:'/checkout',
        name: 'Checkout',
        component: () => import ('@/views/Checkout.vue'),
        meta: { layout: 'empty' },
        beforeEnter: authGuard,
    }
]

const dashboardRoute = {
    path: '/old-dashboard',
    name: 'OldDashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/Dashboard.vue'),
    beforeEnter: authGuard,
}
const newDashboardRoute = {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "NewDashboard" */ '@/views/NewDashboard.vue'),
    beforeEnter: authGuard,
}

routes = [...routes, dashboardRoute, newDashboardRoute]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router
