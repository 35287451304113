<template lang="pug">
    .a-modal.fixed.inset-0.flex.flex-col.z-50.items-center.justify-center.active(
        :class="additionalClass"
        tabindex="0"
        v-if="isVisible"
    )
        div(class="a-modal__content top-0 lg:top-auto absolute rounded-lg p-6 l-box-shadow")
            .bg-orange.float-right.relative.cursor-pointer.rounded-full.offset-5(@click="setModalClose")
                fa-icon.h-3.w-3(icon="times" class="text-white text-sm p-2")
            component(:is="componentName" class="")

        .a-modal__screen-bg.w-full.h-full.inset-0(
            @click="bgClickHandler"
            @keyup.escape="bgClickHandler"
            @keyup.enter="bgClickHandler"
        )
</template>

<script>
import { mapState } from 'vuex'
import ModalDefault from '@/components/partials/ModalDefault'
import ModalViewSurvey from '@/components/partials/ModalViewSurvey'
import ModalSendSurveyInvite from '@/components/partials/ModalSendSurveyInvite'
import ModalCancelSubscription from '@/components/partials/ModalCancelSubscription.vue'
import ModalCopyUrl from '@/components/partials/ModalCopyUrl.vue'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { computed } from '@vue/composition-api'

const { useGetters, useActions } = createNamespacedHelpers('')

export default {
    name: 'Modal',
    components: {
        ModalDefault, ModalViewSurvey, ModalSendSurveyInvite,
        ModalCancelSubscription, ModalCopyUrl
    },
    setup() {
        const { isVisible, componentName, componentData: data } = useGetters(['isVisible', 'componentName', 'componentData'])
        const { setIsVisible, setModalClose } = useActions(['setIsVisible', 'setModalClose'])

        const additionalClass = computed(() => {
            if (data.value?.largeModal) return 'large-modal'
            else if (data.value?.modalClass) return data.value?.modalClass
            return ''
        })
        const bgClickHandler = (e) => {
            if (data.value && !data.value?.keepOpen) setIsVisible(false)
        }

        return { isVisible, componentName, additionalClass, bgClickHandler, setModalClose }
    }
}
</script>
