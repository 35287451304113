<template>
  <div>
    <h3 class="text-gray-700 text-3xl font-medium">Blank Page</h3>
    {{ test }}
    <span>count: {{ count }}</span>
  </div>
</template>

<script>
import { GET_FORM_ANSWERS } from '@/graphql/queries/getFormAnswers.gql'
import { useQuery } from '@vue/apollo-composable'
import { inject, ref } from '@vue/composition-api'
import { logErrorMessages } from '@vue/apollo-util'
import camelcaseKeys from 'camelcase-keys'

export default {
    setup() {
        const test = ref(process.env.GRAPHQL_HTTP)
        const store = inject('vuex-store')
        const count = ref(store.state.count)

        console.log('GET_FORM_ANSWERS', GET_FORM_ANSWERS)
        const { onResult, onError } = useQuery(GET_FORM_ANSWERS)

        onResult(({ data }) => {
            const formAnswers = camelcaseKeys(data.formAnswers, { deep: true })
            console.log('res', formAnswers)
            count.value = formAnswers.length
        })
        onError(error => {
            console.log('error that happened')
            logErrorMessages(error)
        })

        return {
            test,
            count,
        }
    },
}
</script>
