import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { computed, ref, watch } from '@vue/composition-api'
import { SubscriptionType } from '@/common/enums/subscription.type'

const { useGetters } = createNamespacedHelpers("");

export const useTenantSubscription = () => {
    const priceIdMapping = ref({})
    const subscriptionHaveLoaded = ref(false)
    const { signupSession, subscriptions } = useGetters([
        'signupSession',
        'subscriptions'
    ])

    const isFreeTrial = computed(() => {
        return signupSession?.value?.subscriptionType === SubscriptionType.FreeTrial
    })

    const isLite = computed(() => {
        return subscriptionType.value.toLowerCase() === 'lite'
    })

    const isPremium = computed(() => {
        return subscriptionType.value.toLowerCase() === 'premium'
    })

    const subscriptionType = computed(() => {
        const priceId = signupSession.value?.content?.lineItems?.at(0)?.price
        if (!priceId) return ''
        if (!Object.keys(priceIdMapping.value || {})) return

        const foundSubscription = priceIdMapping.value[priceId]
        if (!foundSubscription) return ''
        return foundSubscription?.metadata?.type || ''
    })

    const hasAtLeastLiteOption = computed(() => {
        switch (subscriptionType.value) {
            case 'premium':
            case 'lite':
                return true
            default:
                return false
        }
    })

    watch(subscriptions, (newSubscriptions) => {
        if (!newSubscriptions?.length) return
        newSubscriptions?.forEach((sub) => {
            for (const price of sub?.prices) {
                if (price?.id) priceIdMapping.value[price?.id] = { ...sub }
            }
        })
        subscriptionHaveLoaded.value = true
    }, { immediate: true, deep: true })

    return { hasAtLeastLiteOption, isLite, isPremium, isFreeTrial, subscriptionHaveLoaded }
}
