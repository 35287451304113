import { NODE_API_HTTP } from '@/common/constants'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { axiosInstance } from '@/utils/axios'

export class TenantService {
    constructor() {
        this.nodeApi = `${NODE_API_HTTP}/api/v1`
    }

    async getTenant(tenantId) {
        const url = `${this.nodeApi}/tenants/${tenantId}`
        let tenant = {}
        try {
            const { data } = await axiosInstance.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            tenant = camelcaseKeys(data, { deep: true })
        } catch (error) {
            console.log('error getting tenant', error)
        }
        return tenant
    }

    async tenantExists(shortName) {
        //TODO: maybe change this to an endpoint just just returns 200 or 404 to do a quick check
        const url = `${this.nodeApi}/tenants/by-shortname/${shortName}/check`
        let status = ''
        try {
            const response = await axiosInstance.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            status = response.status === 200 ? 'exists' : 'not exists'
        } catch (error) {
            console.log('error getting tenant', error)
        }
        return status
    }

    async getTenantByShortName(shortName) {
        const url = `${this.nodeApi}/tenants/by-shortname/${shortName}`
        let tenant = {}
        try {
            const { data } = await axiosInstance.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            tenant = camelcaseKeys(data, { deep: true })
        } catch (error) {
            console.log('error getting tenant', error)
        }
        return tenant
    }

    async getTenantOnly(tenantId) {
        const url = `${this.nodeApi}/tenants/${tenantId}/min`
        let tenant = {}
        try {
            const { data } = await axiosInstance.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            tenant = camelcaseKeys(data)
        } catch (error) {
            console.log('error getting tenant', error)
        }
        return tenant
    }

    async getUsersForTenant(tenantId, skip, take) {
        const url = `${this.nodeApi}/tenants/${tenantId}/users?skip=${skip}&take=${take}`
        let users = []
        try {
            const { data } = await axiosInstance.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            users = [...data]
        } catch (error) {
            console.log('error getting users', error)
        }
        return users
    }

    async updateTenant(tenantId, tenant) {
        const url = `${this.nodeApi}/tenants/${tenantId}`
        const data = snakecaseKeys(tenant, { deep: true })
        const { data: updatedTenant } = await axiosInstance.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return camelcaseKeys(updatedTenant)
    }

    async updateKioskForm(tenantId, formSetId) {
        const url = `${this.nodeApi}/tenants/${tenantId}/kiosk-forms/${formSetId}`
        const { data: updatedKioskForm } = await axiosInstance.post(url, {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return camelcaseKeys(updatedKioskForm)
    }

    async getTenantNames(tenantIds) {
        const url = `${this.nodeApi}/tenants/names?tenantIds=${tenantIds.join(',')}`
        let tenants = []
        try {
            const { data } = await axiosInstance.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            tenants = camelcaseKeys(data, { deep: true })
        } catch (error) {
            console.log('error getting tenant', error)
        }
        return tenants
    }

    async getSubscription(tenantId) {
        const url = `${this.nodeApi}/tenants/${tenantId}/subscription`
        let subscription = {}
        try {
            const { data } = await axiosInstance.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            subscription = camelcaseKeys(data, { deep: true })
        } catch (error) {
            console.log('error getting tenant', error)
        }
        return subscription
    }

    async getConstants(tenantId) {
        const url = `${this.nodeApi}/tenants/${tenantId}/constants`
        let constants = []
        try {
            const { data } = await axiosInstance.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            constants = camelcaseKeys(data, { deep: true })
        } catch (error) {
            console.log('error getting tenant', error)
        }
        return constants
    }
}

export const tenantService = new TenantService()
