import { computed, ref } from '@vue/composition-api'
import { defineStore } from 'pinia'
import { tenantService } from '@/services/tenant.service'

export const useTenantDetailsStore = defineStore('tenant-details', () => {
    const tenant = ref({})

    const tenantId = computed(() => tenant.value?.tenantId)
    const parentTenantId = computed(() => tenant.value?.parentTenantId)
    const organization = computed({
        get: () => tenant.value?.organization,
        set: (newOrganization) => {
            tenant.value.organization = { ...newOrganization }
        }
    })

    const tenantForm = computed({
        get: () => tenant.value,
        set: (newTenant) => {
            const org = { ...organization.value }
            tenant.value = { ...newTenant, ...org }
        }
    })

    const config = computed(() => tenant.value?.config)
    const kioskFormSetId = computed(() => {
        return tenant.value?.config?.settings?.kioskFormSetId || tenant.value?.defaultFormSetId
    })

    const updateTenant = (newTenant) => {
        tenant.value = { ...newTenant }
    }

    const updateTenantName = (newTenantName) => {
        tenant.value.name = newTenantName
    }

    const updateTenantShortName = (newTenantShortName) => {
        tenant.value.shortName = newTenantShortName
    }

    const updatePracticeType = (type) => {
        tenant.value.organization.type = type
    }

    const getTenant = async (tenantId) => {
        const data = await tenantService.getTenant(tenantId)
        tenant.value = { ...data }
    }

    return {
        organization,
        tenantForm,
        tenant,
        config,
        tenantId,
        parentTenantId,
        kioskFormSetId,
        updateTenantName,
        updateTenantShortName,
        updatePracticeType,
        updateTenant,
        getTenant
    }
})