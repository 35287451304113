import { API_HTTP, BE_API_KEY, NODE_API_HTTP, API_KEY } from '@/common/constants'
import axios from 'axios'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import { axiosInstance } from '@/utils/axios'

export class FormsService {
    constructor() {
        this.url = `${API_HTTP}/v1/api`
        this.nodeApi = `${NODE_API_HTTP}/api/v1`
    }

    async getFormUrl(tenantId, formSetId) {
        const url = `${this.nodeApi}/forms/${formSetId}/url-link?tenantId=${tenantId}`
        const { data: urlLink } = await axiosInstance.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        console.log('found urlLink', urlLink)
        return camelcaseKeys(urlLink)
    }

    async getDataCsv(tenantId, formSetId, isPss) {
        const url = `${this.url}/forms/${isPss ? 'csv_pss' : 'csv_accred'}`
        const response = await axios.post(url, snakecaseKeys({ tenantId, formSetId }),
            {
                headers: {
                    'x-api-key': BE_API_KEY
                }
            })
        const { data: csvStream } = response
        // console.log('found csvStream Object', csvStream, response)
        return csvStream
    }

    async getQrCodeUrl(tenantId, formSetId) {
        const url = `${this.url}/forms/qr-code-simple/link`
        const data = snakecaseKeys({ formSetId, tenantId })
        const { data: urlLink } = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BE_API_KEY,
                origin: location.origin
            }
        })
        console.log('found urlLink', urlLink)
        return urlLink
    }

    async getPdfUrl(tenantId, formSetId) {
        const url = `${this.nodeApi}/tenants/${tenantId}/forms/${formSetId}/get-pdf-url`
        const { data: urlLink } = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': API_KEY
            }
        })
        console.log('found urlLink', urlLink)
        return urlLink?.url
    }
}

export const formsService = new FormsService()
