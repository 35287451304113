<template lang="pug">
    .m-auto.w-full.h-full
        .bg-navy-lighter.shadow-md.border.border-navy-light.rounded-md.p-4.max-w-600
            h1.text-white.text-center Cancel Subscription

            .text-center.my-12
                | Are you sure you would like to cancel your subscription?
                div.mt-2  If you cancel your subscription you will 
                div lose access to your surveys and dashboard.

            .text-center.mb-2(v-if="showAlert")
                v-alert.mt-8.flex.justify-center.flex-col(
                    v-if="showAlert"
                    :type="alertType"
                    class="text-sm mx-5 mt-8 mb-0"
                ) {{ alertMessage }}
            .text-center
                v-btn(
                    type="button"
                    class="grey mr-4"
                    depressed
                    :disabled="cancellingSubscription"
                    @click="cancelSubscriptionHandler"
                )
                    fa-icon(v-if="cancellingSubscription" icon="spinner" spin class="mr-2 text-primary-light")
                    | Yes, cancel my subscription
                v-btn(
                    depressed
                    color="error"
                    @click="closeHandler"
                ) No, keep my subscription
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import { required } from '@/modules/formRules'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { StripeApiService } from '@/services/stripe-api.service'

const { useActions, useGetters } = createNamespacedHelpers('')
const stripeApiService = new StripeApiService()

export default {
    name: 'ModalCancelSubscription',
    setup() {
        const subscriptionId = ref('')
        const { setModalClose } = useActions(['setModalClose'])
        const { componentData } = useGetters(['componentData'])
        const alertMessage = ref('')
        const alertType = ref('')
        const showAlert = ref(false)
        const cancellingSubscription = ref(false)

        const cancelSubscriptionHandler = async () => {
            if (!subscriptionId.value) return
            const cancelled = await stripeApiService.cancelSubscription(componentData.value.subscriptionId)

            if (cancelled) {
                alertType.value = 'success'
                alertMessage.value = 'Successfully canceled subscription.'
                setTimeout(() => {
                    alertMessage.value = ''
                    showAlert.value = false
                    setModalClose()
                }, 3000)
            } else {
                alertType.value = 'error'
                alertMessage.value = 'Error cancelling your subscription, please try again later...'
            }
            showAlert.value = true
        }

        const closeHandler = () => {
            setModalClose()
        }

        onMounted(() => {
            subscriptionId.value = componentData.value?.subscriptionId
        })

        return {
            alertMessage,
            showAlert,
            alertType,
            cancellingSubscription,
            required,
            cancelSubscriptionHandler,
            closeHandler
        }
    }
}
</script>
