<template lang="pug">
    v-data-table.elevation-1(
        id="dataTable"
        :headers="headers"
        :items="items"
        :loading="loading"
        :loading-text="loadingText"
        :items-per-page.sync="itemsPerPage"
        :footer-props="footerProps"
        @click:row="rowClickHandler"
    )

        template(v-slot:item.name="{item: { name, formType }}")
            .flex.items-center
                //below is RcgpSurveySvg or NpsSurveySvg

                RcgpSurveySvg(v-if="isRcpgSurvey(formType)" class="fill-current w-12 h-auto mr-4")
                NpsSurveySvg(v-else-if="isNps(formType)" class="fill-current w-12 h-auto mr-4")
                //name below displays 'RCGP Patient Feedback' or 'Net Promotor Score'
                span {{ name }}

        template(v-slot:item.published="{item: { published }}")
            .flex.items-center
                fa-icon(:icon="getIcon(published)" class="mr-1" :class="getIconClass(published)")
                span(class="uppercase font-weight-bold" :class="getIconClass(published)")
                    | {{ getText(published) }}
        template(v-slot:item.offlinePdf="{item}")
            .flex.items-center.cursor-pointer(v-if="item.offlinePdf" @click="getPdfLink(item)")
                fa-icon(icon="print" class="mr-1 text-white")
                span(class="font-weight-bold text-white")
                    | Download form
        template(v-slot:item.offlineXlsx="{item}")
            .flex.items-center.cursor-pointer(v-if="item.offlineXlsx" @click="downloadFile(item.xlsxUrl)")
                fa-icon(icon="download" class="mr-1 text-white")
                span(class="font-weight-bold text-white")
                    | Excel form
        template(v-slot:item.downloadLink="{item: { downloadLink }}")
            .flex.items-center
                div.flex.items-center
                    v-btn(
                        rounded
                        depressed
                        color="transparent"
                        class="text-white pr-0"
                        @click.stop.prevent="downloadFile(downloadLink)"
                    )
                        | Download Invoice
        template(v-slot:item.actions="{item}")
            //.flex(v-if="item.formQrCode")
            .flex.items-center
                div.flex.items-center
                    v-btn(
                        rounded
                        depressed
                        color="transparent"
                        class="text-white pr-0"
                        :class="{'disabled pointer-events-none cursor-not-allowed': idIsLoading(item)}"
                        @click.stop="downloadQrCode(item)"
                    )
                        fa-icon(v-if="idIsLoading(item)" icon="spinner" spin class="mr-2")
                        fa-icon(v-else icon="download" class="mr-2")
                        | {{getDownloadButtonText(item)}}
                    v-menu(
                        :close-on-content-click="false"
                        top
                        offset-y
                        open-on-hover
                    )
                        template(v-slot:activator="{ on, attrs }")
                            div(v-bind="attrs" v-on="on" class="p-4 pl-2") 
                                fa-icon(icon="question-circle" class="mr-2")
                        div(class="tool-tip-vmenu")
                            div.flex.items-center
                                QrScanSvg(class="w-200 h-auto fill-current mr-3")
                                div
                                    div.vmenu-title.font-bold Download QR Code
                                    div.text-sm Click 'QR Code' below to download. Print this PDF and place it around your clinic for patients to scan with their mobile device.
                            div.text-sm.mt-3 We recommend having one at the reception and one in a prominent position as people exit the practice.

                div.flex.items-center
                    v-btn(
                        color="transparent"
                        depressed
                        rounded
                        class="text-white ml-4 pr-0"
                        :class="{'disabled pointer-events-none cursor-not-allowed': startingCopyOFormSetId === item.formSetId}"
                        @click.stop="getSurveyUrl($event, item)"
                    )
                        fa-icon(icon="copy" class="mr-2")
                        | {{ copyBtnText(item) }}

                    v-menu(
                        :key="`${item.formSetId}`"
                        :close-on-content-click="false"
                        top
                        offset-y
                        open-on-hover
                    )
                        template(v-slot:activator="{ on, url }")
                            div(v-bind="url" v-on="on" class="p-4 pl-2") 
                                fa-icon(icon="question-circle" class="mr-2")
                        div(class="tool-tip-vmenu")
                            div.vmenu-title.font-bold Copy Survey URL Link
                            div.text-sm.mb-3 Click 'Copy Survey URL' below to copy the survey link and paste it on your device's web browser (Safari on iPads).
                v-btn(
                    depressed
                    rounded
                    color="darkblue text-white mr-4"
                    @click.stop.prevent="downloadCsvHandler(item)"
                )
                    fa-icon(v-if="csvDownloading === item.formSetId" icon="spinner" spin class="mr-2")
                    fa-icon(v-else icon="download" class="mr-2")
                    | {{ csvBtnText(item) }}
                    
                div(
                    class="v-btn v-btn--has-bg v-btn--rounded theme--light v-size--default primary text-white cursor-pointer mr-4"
                    @click.stop="goToDashboard(item.formSetId)"
                )
                    fa-icon(icon="search" class="mr-2")
                    | Dashboard
                v-btn(
                    depressed
                    rounded
                    color="green"
                    :disabled="isKioskFormSet(item) || !!updatingKioskForm"
                    @click.stop.prevent="setAsKioskForm(item)"
                )
                    fa-icon(v-if="updatingKioskForm === item.formSetId" icon="spinner" spin class="mr-2")
                    //fa-icon(v-else icon="download" class="mr-2")
                    | {{ setKioskBtnText(item) }}
                //TODO: add back when the gen of the business card has been added
                //v-btn(
                //    depressed
                //    raised
                //    color="lightgreen"
                //    text
                //)
                //    fa-icon(icon="download" class="mr-2")
                //    | QR Code Business Card
        //template(v-slot:item.editRecord="{item}")
            //.text-right.flex.justify-end
                //a(
                //   href="#"
                //    class="hover:text-primary mx-2"
                //    @click.prevent="actionHandler(item)"
                //)
                //    fa-icon(icon="chevron-right" class="text-navy-lightest ml-3")
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import RcgpSurveySvg from '@/assets/icons/icon-survey-rcgp.svg'
import NpsSurveySvg from '@/assets/icons/icon-survey-nps.svg'
import QrScanSvg from '@/assets/icons/qr-scan.svg'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import Loader from '@/assets/loader.svg'
import { FormsService } from '@/services/forms.service'
import { useFormHelpers } from '@/composables/useFormHelpers'
import { useFormSetStore } from '@/stores/useFormSetStore'
import { useSurveysTableStore } from '@/stores/useSurveysTableStore'
import { storeToRefs } from 'pinia'
import { useTenantDetailsStore } from '@/stores/useTenantDetailsStore'

const { useState } = createNamespacedHelpers('app')
const { useActions, useGetters } = createNamespacedHelpers('')

const formsService = new FormsService()
export default {
    name: 'DataTable',
    components: {
        RcgpSurveySvg,
        NpsSurveySvg,
        QrScanSvg,
        Loader
    },
    props: {
        headers: {
            type: Array,
            default: () => [],
        },
        items: {
            type: Array,
            default: () => [],
        },
        rowClickHandler: {
            type: Function,
            default: () => {
            },
        },
        loading: {
            type: Boolean,
            default: false,
        },
        loadingText: {
            type: String,
            default: '',
        },
        rowLoading: {
            type: Boolean,
            default: false,
        },
        actionHandler: {
            type: Function,
            default: () => {
            },
        },
        // tenantId: {
        //     type: String,
        //     default: '',
        // },
        //TODO: Need to change the above datatable to accept a list of custom column/slots to be able to make this generic: liek in this link:
        // https://stackoverflow.com/questions/61508925/is-it-possible-to-use-dynamic-scoped-slots-to-override-column-values-inside-v-d
        customColumns: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['download-file', 'go-to-url', 'download-url', 'download-csv', 'update-kiosk-form'],
    setup({ rowLoading, items }, { emit, root }) {
        //TODO: move to constants
        
        const router = root.$router
        const surveysTableStore = useSurveysTableStore()
        const tenantDetailsStore = useTenantDetailsStore()
        const { tenantId } = useGetters(['tenantId'])
        const { setQrCodeIdLoading, idIsLoading, setCsvDownloading, setUpdatingKioskForm } = surveysTableStore
        const { csvDownloading, updatingKioskForm } = storeToRefs(surveysTableStore)
        const { kioskFormSetId } = storeToRefs(tenantDetailsStore)
        const { setModalOpen } = useActions(['setModalOpen'])
        const qrCodeLoading = ref(false)
        const rowIdLoading = ref('')
        const itemsPerPage = ref(25)
        const hasCopiedToClipboard = ref('')
        const startingCopyOFormSetId = ref('')
        const footerProps = ref({
            'items-per-page-options': [10, 25, 50, 100],
        })
        const dataItems = ref([])
        const surveyURL = ref('https://survey.url.displays.here.com/yay')
        const showSurveyLink = ref(false)
        const formSetStore = useFormSetStore()
        const { updateFormSetId } = formSetStore

        const { isRcpgSurvey, isNps } = useFormHelpers()

        const getIcon = (isPublished) => {
            return isPublished ? 'check' : 'pencil-alt'
        }
        const getIconClass = (isPublished) => {
            return isPublished ? 'text-green' : 'text-yellow'
        }
        const getText = (isPublished) => {
            return isPublished ? 'Published' : 'Draft'
        }
        const csvBtnText = (item) => {
            return item.formSetId === csvDownloading.value ? 'Please wait...' : 'Data CSV'
        }
        const setKioskBtnText = (item) => {
            if (isKioskFormSet(item)) return 'Current feedback form'
            return item.formSetId === updatingKioskForm.value ? 'Please wait...' : 'Set as Feedback form'
        }
        const isKioskFormSet = (item) => {
            return item?.formSetId === kioskFormSetId.value
        }

        // most of the below needs to be moved out to helpers or to the parent since it's very specialised to 1 component

        const getPdfLink = async (item) => {
            let url = ''
            if (item.offlinePdfPath) {
                // now we get the generated pdf link from the server
                const pdfLink = await formsService.getPdfUrl(tenantId.value, item.formSetId)
                url = pdfLink
            } else {
                url = item.pdfUrl
            }
            downloadFile(url)
        }
        
        const downloadFile = (urlLink) => {
            emit('download-url', urlLink)
        }

        const downloadQrCode = (item) => {
            rowIdLoading.value = item?.formSetId || null
            setQrCodeIdLoading(item?.formSetId)
            // downloadFileHandler(item?.formQrCode?.pdfUrl, item?.formQrCode?.pdfName)
            emit('download-file', { formSetId: item?.formSetId, tenantId: tenantId.value })
        }
        const viewForm = (item) => {
            // first we get the qr code
            emit('go-to-url', item.formSetId)
        }

        const getDownloadButtonText = (item) => {
            return item?.formSetId === rowIdLoading.value && rowLoading
                ? 'Please wait...'
                : 'QR Code'
        }

        const openCopyUrlModal = () => {
            setModalOpen({
                isVisible: true,
                data: {
                    urls: surveyURL.value,
                    keepOpen: false,
                    modalClass: ''
                },
                componentName: 'ModalCopyUrl'
            })
            // navigator.clipboard.writeText(surveyURL.value)
            // hasCopiedToClipboard.value = item.formSetId
        }

        const getSurveyUrl = async (event, item) => {
            // if the mouse is leaving the button area then we skip the execution
            if (!event) return
            startingCopyOFormSetId.value = item.formSetId
            showSurveyLink.value = true
            qrCodeLoading.value = true
            formsService.getFormUrl(tenantId.value, item.formSetId)
                .then((url) => {
                    surveyURL.value = url
                    openCopyUrlModal()
                })
                .finally(() => {
                    startingCopyOFormSetId.value = ''
                    qrCodeLoading.value = false
                })
        }

        const copyBtnText = (item) => {
            if (item.formSetId === hasCopiedToClipboard.value) return 'Copied!'
            else if (item.formSetId === startingCopyOFormSetId.value) return 'Please wait...'
            return 'Copy Survey URL'
        }

        const downloadCsvHandler = (item) => {
            setCsvDownloading(item.formSetId)
            emit('download-csv', {formSetId: item.formSetId, tenantId: tenantId.value, formName: item.name, isPss: isNps(item.formType)})
        }

        const goToDashboard = async (formSetId) => {
            await updateFormSetId(formSetId)
            router.push({name: 'Dashboard'})
        }

        const setAsKioskForm = async (item) => {
            // do the thing
            setUpdatingKioskForm(item.formSetId)
            emit('update-kiosk-form', {formSetId: item.formSetId, tenantId: tenantId.value})
        }

        watch(
            () => rowLoading.value,
            (nextVal, prevValue) => {
                // here we check if 'rowLoading' went from 'true' to false
                if (prevValue && !nextVal) rowIdLoading.value = ''
            }
        )

        watch(() => items.value, (nextVal) => {
            if (!nextVal || !nextVal.length) return
            dataItems.value = [...nextVal]
        })

        watch(hasCopiedToClipboard, (copiedToClipboard) => {
            if (!copiedToClipboard) return
            setTimeout(() => {
                hasCopiedToClipboard.value = ''
            }, 2000)
        })

        return {
            itemsPerPage,
            footerProps,
            dataItems,
            surveyURL,
            qrCodeLoading,
            hasCopiedToClipboard,
            showSurveyLink,
            startingCopyOFormSetId,
            csvDownloading,
            updatingKioskForm,
            idIsLoading,
            isRcpgSurvey,
            isNps,
            isKioskFormSet,
            csvBtnText,
            getIcon,
            getIconClass,
            getText,
            downloadQrCode,
            viewForm,
            getDownloadButtonText,
            getSurveyUrl,
            openCopyUrlModal,
            copyBtnText,
            goToDashboard,
            downloadFile,
            downloadCsvHandler,
            setKioskBtnText,
            setAsKioskForm,
            getPdfLink
        }
    },
}
</script>
