import gql from 'graphql-tag'

const GET_CATEGORIES_BY_FORM_SET_ID = gql`
    query getCategoriesByFormSetId($tenantId: uuid = "", $parentTenantId: uuid = "", $formSetId: uuid = "") {
        categories: form_builder_hasura_category(
            where: {
                _and: [
                    {
                        _or: [
                            {
                                tenant_id: {
                                    _eq: $tenantId
                                }
                            },
                            {
                                tenant_id: {
                                    _eq: $parentTenantId
                                }
                            }
                        ]
                    },
                    {
                        question_categories: {
                            question: {
                                question_pools: {
                                    form_set_id: {
                                        _eq: $formSetId
                                    }
                                }
                            }
                        }
                    },
                    {
                        _or: [
                            {
                                tags: {
                                    _is_null: true
                                }
                            }, {
                                tags: {
                                    _nilike: "%NOT_PRE_SURVEY%"
                                }
                            }
                        ]
                    }
                ]
            }
        ) {
            name
            category_id
            tenant_id
        }
    }
`


const GET_CATEGORIES = gql`
    query getCategories($tenantId: uuid = "", $parentTenantId: uuid = "") {
        categories: form_builder_hasura_category(
            where: {
                _or: [
                    {
                        tenant_id: {
                            _eq: $tenantId
                        }
                    },
                    {
                        tenant_id: {
                            _eq: $parentTenantId
                        }
                    }
                ]
            }
        ) {
            name
            category_id
            tenant_id
        }
    }
`

export {
    GET_CATEGORIES,
    GET_CATEGORIES_BY_FORM_SET_ID
}
