import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{staticClass:"elevation-1",attrs:{"id":"dataTable","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":_vm.loadingText,"items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"click:row":_vm.rowClickHandler},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var ref_item = ref.item;
var name = ref_item.name;
var formType = ref_item.formType;
return [_c('div',{staticClass:"flex items-center"},[(_vm.isRcpgSurvey(formType))?_c('RcgpSurveySvg',{staticClass:"fill-current w-12 h-auto mr-4"}):(_vm.isNps(formType))?_c('NpsSurveySvg',{staticClass:"fill-current w-12 h-auto mr-4"}):_vm._e(),_c('span',[_vm._v(_vm._s(name))])],1)]}},{key:"item.published",fn:function(ref){
var published = ref.item.published;
return [_c('div',{staticClass:"flex items-center"},[_c('fa-icon',{staticClass:"mr-1",class:_vm.getIconClass(published),attrs:{"icon":_vm.getIcon(published)}}),_c('span',{staticClass:"uppercase font-weight-bold",class:_vm.getIconClass(published)},[_vm._v(_vm._s(_vm.getText(published)))])],1)]}},{key:"item.offlinePdf",fn:function(ref){
var item = ref.item;
return [(item.offlinePdf)?_c('div',{staticClass:"flex items-center cursor-pointer",on:{"click":function($event){return _vm.getPdfLink(item)}}},[_c('fa-icon',{staticClass:"mr-1 text-white",attrs:{"icon":"print"}}),_c('span',{staticClass:"font-weight-bold text-white"},[_vm._v("Download form")])],1):_vm._e()]}},{key:"item.offlineXlsx",fn:function(ref){
var item = ref.item;
return [(item.offlineXlsx)?_c('div',{staticClass:"flex items-center cursor-pointer",on:{"click":function($event){return _vm.downloadFile(item.xlsxUrl)}}},[_c('fa-icon',{staticClass:"mr-1 text-white",attrs:{"icon":"download"}}),_c('span',{staticClass:"font-weight-bold text-white"},[_vm._v("Excel form")])],1):_vm._e()]}},{key:"item.downloadLink",fn:function(ref){
var downloadLink = ref.item.downloadLink;
return [_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex items-center"},[_c(VBtn,{staticClass:"text-white pr-0",attrs:{"rounded":"","depressed":"","color":"transparent"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.downloadFile(downloadLink)}}},[_vm._v("Download Invoice")])],1)])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex items-center"},[_c(VBtn,{staticClass:"text-white pr-0",class:{'disabled pointer-events-none cursor-not-allowed': _vm.idIsLoading(item)},attrs:{"rounded":"","depressed":"","color":"transparent"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadQrCode(item)}}},[(_vm.idIsLoading(item))?_c('fa-icon',{staticClass:"mr-2",attrs:{"icon":"spinner","spin":""}}):_c('fa-icon',{staticClass:"mr-2",attrs:{"icon":"download"}}),_vm._v(_vm._s(_vm.getDownloadButtonText(item)))],1),_c(VMenu,{attrs:{"close-on-content-click":false,"top":"","offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"p-4 pl-2"},'div',attrs,false),on),[_c('fa-icon',{staticClass:"mr-2",attrs:{"icon":"question-circle"}})],1)]}}],null,true)},[_c('div',{staticClass:"tool-tip-vmenu"},[_c('div',{staticClass:"flex items-center"},[_c('QrScanSvg',{staticClass:"w-200 h-auto fill-current mr-3"}),_c('div',[_c('div',{staticClass:"vmenu-title font-bold"},[_vm._v("Download QR Code")]),_c('div',{staticClass:"text-sm"},[_vm._v("Click 'QR Code' below to download. Print this PDF and place it around your clinic for patients to scan with their mobile device.")])])],1),_c('div',{staticClass:"text-sm mt-3"},[_vm._v("We recommend having one at the reception and one in a prominent position as people exit the practice.")])])])],1),_c('div',{staticClass:"flex items-center"},[_c(VBtn,{staticClass:"text-white ml-4 pr-0",class:{'disabled pointer-events-none cursor-not-allowed': _vm.startingCopyOFormSetId === item.formSetId},attrs:{"color":"transparent","depressed":"","rounded":""},on:{"click":function($event){$event.stopPropagation();return _vm.getSurveyUrl($event, item)}}},[_c('fa-icon',{staticClass:"mr-2",attrs:{"icon":"copy"}}),_vm._v(_vm._s(_vm.copyBtnText(item)))],1),_c(VMenu,{key:("" + (item.formSetId)),attrs:{"close-on-content-click":false,"top":"","offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var url = ref.url;
return [_c('div',_vm._g(_vm._b({staticClass:"p-4 pl-2"},'div',url,false),on),[_c('fa-icon',{staticClass:"mr-2",attrs:{"icon":"question-circle"}})],1)]}}],null,true)},[_c('div',{staticClass:"tool-tip-vmenu"},[_c('div',{staticClass:"vmenu-title font-bold"},[_vm._v("Copy Survey URL Link")]),_c('div',{staticClass:"text-sm mb-3"},[_vm._v("Click 'Copy Survey URL' below to copy the survey link and paste it on your device's web browser (Safari on iPads).")])])])],1),_c(VBtn,{attrs:{"depressed":"","rounded":"","color":"darkblue text-white mr-4"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.downloadCsvHandler(item)}}},[(_vm.csvDownloading === item.formSetId)?_c('fa-icon',{staticClass:"mr-2",attrs:{"icon":"spinner","spin":""}}):_c('fa-icon',{staticClass:"mr-2",attrs:{"icon":"download"}}),_vm._v(_vm._s(_vm.csvBtnText(item)))],1),_c('div',{staticClass:"v-btn v-btn--has-bg v-btn--rounded theme--light v-size--default primary text-white cursor-pointer mr-4",on:{"click":function($event){$event.stopPropagation();return _vm.goToDashboard(item.formSetId)}}},[_c('fa-icon',{staticClass:"mr-2",attrs:{"icon":"search"}}),_vm._v("Dashboard")],1),_c(VBtn,{attrs:{"depressed":"","rounded":"","color":"green","disabled":_vm.isKioskFormSet(item) || !!_vm.updatingKioskForm},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.setAsKioskForm(item)}}},[(_vm.updatingKioskForm === item.formSetId)?_c('fa-icon',{staticClass:"mr-2",attrs:{"icon":"spinner","spin":""}}):_vm._e(),_vm._v(_vm._s(_vm.setKioskBtnText(item)))],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }