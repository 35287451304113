<template>
    <!-- TODO: remove the inline style tag to css class -->
    <div
        id="header"
        class="w-full w-full flex items-center justify-between px-8"
    >
        <div class="flex items-center h-full">
            <!--
            <div v-if="!showOnlyLogo" class="menuButton inline-block">
                <v-btn icon data-cy="menuButton" @click.stop="toggleDrawer">
                    <fa-icon icon="bars" class="text-white" />
                </v-btn>
            </div>-->
            <div class="logo-area">
                <img
                    src="@/assets/images/ultimateq-tenant-logo.png"
                    alt="UltimateQ"
                    class="logo"
                />
            </div>
            <Sidebar v-if="subscriptionsLoaded" />
        </div>
        <div v-if="!showOnlyLogo" class="relative flex items-center">
            <!-- TODO: check if we still need this, it makes more sense for this to be a modal here -->
            <!--            <v-btn-->
            <!--                class=""-->
            <!--                depressed-->
            <!--                raised-->
            <!--                @click="openSendSurveyInvite"-->
            <!--            >-->
            <!--                Send invite to survey-->
            <!--            </v-btn>-->
            <v-menu :nudge-bottom="15" :rounded="true" offset-y>
                <template v-slot:activator="{ attrs, on }">
                    <button
                        class="flex items-center mr-8"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <div class="mx-2 text-sm text-grey-2">
                            {{ tenantName }}
                        </div>
                        <fa-icon
                            v-if="hasMultipleTenants"
                            icon="chevron-down"
                            class="text-grey-2 text-xs"
                        />
                    </button>
                </template>
                <v-list
                    v-if="hasMultipleTenants"
                    class="border-none l-box-shadow h-350 overflow-scroll"
                >
                    <TenantList />
                </v-list>
            </v-menu>
            <v-menu :nudge-bottom="15" :rounded="true" offset-y>
                <template v-slot:activator="{ attrs, on }">
                    <button class="flex items-center" v-bind="attrs" v-on="on">
                        <div class="h-6 w-6 rounded-full overflow-hidden">
                            <img
                                class="h-full w-full object-cover"
                                src="@/assets/images/empty_user.png"
                                alt="Your avatar"
                            />
                        </div>
                        <div class="mx-2 text-sm text-grey-2">
                            {{ fullName }}
                        </div>
                        <fa-icon
                            icon="chevron-down"
                            class="text-text-color text-xs"
                        />
                    </button>
                </template>
                <v-list
                    class="border-none py-2 w-48 rounded-md z-20 w-160 l-box-shadow dropdown-box"
                >
                    <v-list-item link>
                        <router-link
                            :to="{ name: 'ChangePlan' }"
                            class="block py-2 text-sm text-grey-2 hover:text-primary w-full"
                        >Change Plan</router-link>
                    </v-list-item>
                    <!--
                    <v-list-item link>
                        <router-link
                            :to="{ name: 'AccountSettings' }"
                            class="block py-2 text-sm text-grey-2 hover:text-primary w-full"
                        >Account Settings</router-link>
                    </v-list-item>
                -->

                    <v-list-item link>
                        <router-link
                            :to="{ name: 'ChangeHardware' }"
                            class="block py-2 text-sm text-grey-2 hover:text-primary w-full"
                        >Hardware Options</router-link>
                    </v-list-item>
                    
                    <v-list-item link>
                        <router-link
                            :to="{ name: 'BillingHistory' }"
                            class="block py-2 text-sm text-grey-2 hover:text-primary w-full"
                        >Billing History</router-link>
                    </v-list-item>
                    <v-list-item link>
                        <div
                            class="block pointer-events-auto py-2 text-sm text-grey-2 hover:text-primary w-full"
                            @click="logout(authInstance)"
                        >
                            Log out
                        </div>
                    </v-list-item>
                    <!--Remove Profile settings after Add/Edit Practice Details components are setup
                    <v-list-item link>
                        <router-link
                            :to="{ name: 'ProfileSettings', query: { t: tenantId } }"
                            class="block py-2 text-sm text-grey-2 hover:text-primary w-full"
                        >My Profile</router-link>
                    </v-list-item>-->
                </v-list>
            </v-menu>
            <div class="logo-area">
                <a href="https://www.ultimateq.health/user-guide" target="_blank" title="Open User guide">
                    <img
                        src="@/assets/images/user_guide.png"
                        alt="UltimateQ"
                        class="ml-4 logo"
                    />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import { getInstance } from '@/auth'
import { useSidebar } from '@/composables/useSidebar'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import TenantList from '@/components/shared/partials/TenantList'
import Sidebar from '@/components/Sidebar'
import { logout } from '@/utils/user.helpers'
import { tenantService } from '../services/tenant.service'

const { useState } = createNamespacedHelpers('app')
const { useActions, useGetters } = createNamespacedHelpers('')

export default {
    name: 'Header',
    components: { TenantList, Sidebar },
    setup(props, { emit, root }) {
        const logo = ref(
            'https://assets.website-files.com/628193c332beee40f7843b3d/6281a0d0fca88d2f0c58e65e_ultimateq_logo.png'
        )
        const tenantName = ref('')
        const showOnlyLogo = ref(false)
        const { isOpen } = useSidebar()
        const drawer = ref(false)
        // TODO: need to change to getting the tenant Id from Auth0
        const { subscriptionsLoaded } = useGetters([ 'subscriptionsLoaded'])
        const { tenantId, tenantIds, loggedInUser } = useState([
            'tenantId',
            'tenantIds',
            'loggedInUser',
        ])
        const { setParentTenantId } = useActions(['setParentTenantId'])
        const toggleDrawer = () => {
            emit('drawer-updated', !drawer.value)
        }
        const authInstance = computed(() => getInstance())
        const fullName = computed(
            () =>
                `${loggedInUser.value.firstName ?? ''} ${
                    loggedInUser.value.lastName ?? ''
                }`
        )
        const hasMultipleTenants = computed(
            () => (tenantIds.value?.length || -1) > 1
        )
        const currentRoute = computed(() => root.$route)
        const tenantInitialLoad = ref(true)
        const { setModalOpen } = useActions(['setModalOpen'])

        const getTenant = async () => {
            tenantInitialLoad.value = true
            const resp = await tenantService.getTenantOnly(tenantId.value)
            const tenant = { ...resp || [] }
            tenantName.value = tenant.name
            await setParentTenantId(tenant.parentTenantId)
            tenantInitialLoad.value = false
        }

        const openSendSurveyInvite = () => {
            setModalOpen({
                isVisible: true,
                data: {
                    keepOpen: true,
                    modalClass: 'large-modal',
                },
                componentName: 'ModalSendSurveyInvite',
            })
        }

        // region: watches
        watch(
            () => tenantId.value,
            async (nextValue) => {
                if (nextValue) {
                    await getTenant()
                }
            }
        )

        watch(() => currentRoute.value, (value) => {
            showOnlyLogo.value = value?.name === 'PracticeInvite'
        }, { deep: true })

        return {
            logo,
            isOpen,
            authInstance,
            tenantId,
            fullName,
            hasMultipleTenants,
            tenantName,
            showOnlyLogo,
            subscriptionsLoaded,
            toggleDrawer,
            logout,
            openSendSurveyInvite,
        }
    },
}
</script>
