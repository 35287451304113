<template lang="pug">
    router-link.flex.items-center(
        data-cy="backLink"
        :to="backRoute"
    )
        fa-icon(icon="chevron-left" class="mr-1")
        .font-bold.text-xs.uppercase(v-html="linkText")
</template>

<script>
export default {
    name: "BackLink",
    props: {
        backRoute: {
            type: Object,
            default: () => {}
        },
        linkText: {
            type: String,
            default: ''
        }
    }
}
</script>
