import gql from 'graphql-tag'

const GET_USER_BY_EMAIL = gql`query getUserByEmail($email: String!) {
    users: form_builder_hasura_user(
        where: {email: {_eq: $email}}
    ) {
        user_id
        first_name
        last_name
        subscribe_to_newsletter
        accpeted_terms_and_conditions
        tenant {
            tenant_id
            name
        }
        profile: organization {
            name
            type
            branch_or_main
            abn
            structure
            mmm
            phn
            website
            number_of_members
            completed_signup
            address {
                unit
                address_line1
                address_line2
                suburb
                postcode
                state
            }
        }
    }
}
`

const GET_USER_BY_AUTH0_ID = gql`query getUserByAuth0Id($auth0Id: String!) {
    users: form_builder_hasura_user(
        where: {auth0_id: {_eq: $auth0Id}}
    ) {
        user_id
        first_name
        last_name
        subscribe_to_newsletter
        accpeted_terms_and_conditions
        tenant {
            tenant_id
            name
        }
        profile: organization {
            name
            type
            branch_or_main
            abn
            structure
            mmm
            phn
            website
            number_of_members
            completed_signup
            address {
                unit
                address_line1
                address_line2
                suburb
                postcode
                state
            }
        }
    }
}
`

export {
    GET_USER_BY_EMAIL,
    GET_USER_BY_AUTH0_ID
}
