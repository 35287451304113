import { defineStore } from 'pinia'
import { computed, ref } from '@vue/composition-api'
import { getOrCreateProvider } from '@/vue-apollo'
import {
    GET_SURVEY_STATS_BY_QUESTION,
    GET_SURVEYS_RACGP_COMPLETED_STATS_RESPONSE
} from '@/graphql/queries/formStatQueries.gql'
import camelcaseKeys from 'camelcase-keys'

const apolloClient = getOrCreateProvider()?.defaultClient

export const useFormSetStore = defineStore('formSets', () => {
    const formSetId = ref('')
    const _practiceSurveyStats = ref({})
    const _practiceSurveyByCategoryStats = ref({})
    const _peerSurveyStats = ref({})
    const _peerSurveyByCategoryStats = ref({})
    const _category = ref('')
    const _surveyStateLoading = ref(false)

    const practiceSurveyStats = computed(() => _practiceSurveyStats.value)
    const practiceSurveyByCategoryStats = computed(() => _practiceSurveyByCategoryStats.value)
    const peerSurveyStats = computed(() => _peerSurveyStats.value)
    const peerSurveyByCategoryStats = computed(() => _peerSurveyByCategoryStats.value)
    const category = computed(() => _category)
    const surveyStateLoading = computed(() => _surveyStateLoading.value)

    const updateFormSetId = (newFormSetId) => {
        formSetId.value = newFormSetId
    }
    const updateCategory = (newCategory) => {
        _category.value = newCategory
    }

    const getSurveyStatsByQuestion = async (tenantIdCompare, formSetIdCompare, startDate, endDate, forPractice = true) => {
        _surveyStateLoading.value = true
        let variables = { startDate, endDate }
        if (tenantIdCompare) variables = { ...variables, tenantIdCompare }
        if (formSetIdCompare) variables = { ...variables, formSetIdCompare }
        let response = await apolloClient.query({
            query: GET_SURVEYS_RACGP_COMPLETED_STATS_RESPONSE, variables
        })
        response = camelcaseKeys(response, { deep: true })
        if (forPractice) _practiceSurveyStats.value = { ...(response || {}) }
        else _peerSurveyStats.value = { ...(response || {}) }
        _surveyStateLoading.value = false
    }
    const getSurveyStatsByQuestionAndCategory = async (tenantIdCompare, formSetIdCompare, categoryCompare, startDate, endDate, forPractice = true) => {
        let variables = { startDate, endDate }
        if (tenantIdCompare) variables = { ...variables, tenantIdCompare }
        if (formSetIdCompare) variables = { ...variables, formSetIdCompare }
        let response = await apolloClient.query({
            query: GET_SURVEYS_RACGP_COMPLETED_STATS_RESPONSE, variables
        })
        response = camelcaseKeys(response, { deep: true })
        if (forPractice) _practiceSurveyByCategoryStats.value =  {...(response || {}) }
        else _peerSurveyByCategoryStats.value = { ...(response || {}) }
    }

    return {
        formSetId, practiceSurveyStats, practiceSurveyByCategoryStats,
        peerSurveyStats, peerSurveyByCategoryStats, category, surveyStateLoading, updateFormSetId,
        updateCategory, getSurveyStatsByQuestion, getSurveyStatsByQuestionAndCategory
    }
})
