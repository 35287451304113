import { defineStore } from 'pinia'
import { computed, ref } from '@vue/composition-api'

export const useSurveysTableStore = defineStore('surveys-table', () => {
    const _qrCodeIdLoading = ref('')
    const _csvDownloading = ref(false)
    const _updatingKioskForm = ref(false)

    const qrCodeIdLoading = computed(() => _qrCodeIdLoading.value)
    const csvDownloading = computed(() => _csvDownloading.value)
    const updatingKioskForm = computed(() => _updatingKioskForm.value)

    const setQrCodeIdLoading = (qrCodeId) => {
        _qrCodeIdLoading.value = qrCodeId
    }
    const setCsvDownloading = (value) => {
        _csvDownloading.value = value
    }

    const setUpdatingKioskForm = (value) => {
        _updatingKioskForm.value = value
    }

    const idIsLoading = (item) => {
        return _qrCodeIdLoading.value === item?.formSetId
    }

    return {
        qrCodeIdLoading,
        csvDownloading,
        updatingKioskForm,
        setQrCodeIdLoading,
        idIsLoading,
        setCsvDownloading,
        setUpdatingKioskForm
    }
})
