<template lang="pug">
    .c-phone-number.v-text-field
        v-form(ref="phoneNumberForm" v-model="isValid")
            v-phone-input(
                ref="phone"
                default-country-code="AU"
                v-model="localPhone.phone"
                :translations="translations"
                @phone-number-blue="onPhoneBlur"
            )
            .v-text-field__details(v-if="error")
                .v-messages.theme--light.error--text(role="alert")
                    .v-messages__wrapper
                        .v-messages__message.message-transition-enter-to {{ error }}
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'

export default {
    name: 'PhoneNumber',
    props: {
        value: {
            type: Object,
            required: true,
            default: () =>  {
                return {
                    countryCode: 'AU',
                    phone: '',
                    archived: false
                }
            }
        },
        translations: {
            type: Object,
            default: () => {}
        },
        required: Boolean
    },
    emits: ['update-phone'],
    setup(props, { emit }) {
        const isValid = ref(true)
        const error = ref('')
        const localPhone = ref({...props.value})
        const required = computed(() => props.required)

        // // life cycle composables
        // onMounted(() => {
        //     localPhone.value = props.value
        // })

        // const onPhoneBlur = () => {
        //     console.log('phone bluring')
        //     emit('update-phone', localPhone.value)
        // }
        const validate = () => {
            const isValid = !required.value || !!localPhone.value.phone || 'Required.'

            error.value = isValid.value ? '' : isValid
            isValid.value = isValid.value === true

            return isValid.value
            // return true
        }

        const onPhoneBlur = () => {
            //TODO: add in the Vuetify way of validating
            // if (this.isValid) {
            //     this.$refs.phoneNumberForm.resetFields()
            // } else {
            //     this.$refs.phoneNumberForm.validate()
            // }
            emit('input', localPhone.value)
            emit('blur', localPhone.value)
        }
        watch(() => props.value.phone, () => {
            localPhone.value = props.value
        })

        watch(() => localPhone.value.phone, (nextVal) => {
            emit('update-phone', nextVal)
        })

        return {
            isValid,
            error,
            localPhone,
            validate,
            onPhoneBlur
        }
    }
}
</script>
