import { getInstance } from "./index"
import store from '@/store/index'
import { getOrCreateProvider } from '@/vue-apollo'
import { GET_TENANT_ORGANIZATION } from '@/graphql/queries/getTenantByShortName.gql'
import camelcaseKeys from 'camelcase-keys'
import router from '@/router'
import { ENV } from '@/common/constants'

const apolloProvider = getOrCreateProvider()

const ALLOWED_ENDPOINTS = ['AccountSettings', 'ProfileSettings', 'BillingHistory', 'ChangePlan', 'UserList', 'UserDetails']
export const authGuard = async (to, from, next) => {
    const authService = getInstance()
    const query = to.query
    const invitation = query?.invitation
    const organization = query?.organization

    // if (!(await authService.initiateLoginProcess())) {
    //     return next({
    //         name: 'network-error',
    //         params: {
    //             message: authService.error?.message,
    //             redirectPath: to
    //         }
    //     })
    // }

    const guardAction = async () => {
        // If the user is authenticated, continue with the route
        if (authService.isAuthenticated) {
            await store.dispatch('setPermissions', { vm: authService })

            // if (ENV !== 'develop') {
            //     // TODO: need to add back check for completedSignup when we have hooked up the signup process correctly
            //     // const {profile: {completedSignup}} = store.state.app.loggedInUser
            //     const user = store.state.app.loggedInUser
            //     const tenantId = user?.tenant?.tenantId
            //     const { data: { organizations } } = await apolloProvider.defaultClient.query({
            //         query: GET_TENANT_ORGANIZATION,
            //         variables: { tenantId }
            //     })
            //     const organization = camelcaseKeys(organizations.at(0))
            //     // // next we need to check whether the tenant has been configured - i.e. updated the addresses, etc
            //     // // we check the flag on tenant
            //     // if (!organization?.completedSignup && to.name !== 'EditOrganisation') {
            //     //     return router.push({name: 'EditOrganisation'})
            //     // }
            //     if (!organization?.completedSignup && to.name !== 'Forms' && ALLOWED_ENDPOINTS.indexOf(to.name) === -1) {
            //         return router.push({name: 'Forms'})
            //     }
            // }

            return next()
        }
        // Otherwise, log in
        await authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
    }
    
    // If loading has already finished, check our auth state using `guardAction()`
    if (!authService.loading) {
        return await guardAction()
    }
    
    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch('loading', async loading => {
        if (!loading) {
            return await guardAction()
        }
    })
}
