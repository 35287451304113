const ENV = process.env.VUE_APP_ENV
const CS_AUTH0_CLAIM_ID = process.env.VUE_APP_CS_AUTH0_CLAIM_ID
const API_KEY_SECRET = process.env.VUE_APP_BE_API_KEY || ''
const DEBUG = process.env.VUE_APP_DEBUG
const AUTH0_DOMAIN = process.env.VUE_APP_AUTH0_DOMAIN
const AUTH0_CLIENT_ID = process.env.VUE_APP_AUTH0_CLIENT_ID
const AUTH0_ORGANIZATION_ID = process.env.VUE_APP_AUTH0_ORGANIZATION_ID
const API_HTTP = process.env.VUE_APP_API_HTTP
const NODE_API_HTTP = process.env.VUE_APP_NODE_API_HTTP
const REPORTS_API_HTTP = process.env.VUE_APP_REPORTS_API_HTTP
const GMAP_API_KEY = process.env.VUE_APP_GMAP_API_KEY
const IFRAME_CHILD = process.env.VUE_APP_IFRAME_CHILD
const PROJECT_ENV = process.env.NODE_ENV
export const API_KEY = process.env.VUE_APP_NODE_API_KEY
export const ULTIMATEQ_CONTACT_PAGE = process.env.VUE_APP_ULTIMATEQ_CONTACT_PAGE
const MAX_SHORTNAME_LENGTH = process.env.VUE_APP_MAX_SHORTNAME_LENGTH || 20

const STRIPE_PUBLISHABLE_KEY = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
const STRIPE_PAYMENT_SUCCESS_URL = process.env.VUE_APP_STRIPE_PAYMENT_SUCCESS_URL
const STRIPE_PAYMENT_CANCEL_URL = process.env.VUE_APP_STRIPE_PAYMENT_CANCEL_URL
const STRIPE_TEST_MODE = JSON.parse(process.env.VUE_APP_STRIPE_TEST_MODE || 'true')

const BE_API_KEY = process.env.VUE_APP_BE_API_KEY

const HARDWARE_AGREEMENT_LINK = 'https://www.ultimateq.health/terms-of-service'
const HARDWARE_OPTIONS_LINK = 'https://www.ultimateq.health/hardware-options'

const GUID_REGEX = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/

export const LONG_ACCRED_SURVEY_NAME = 'long accreditation survey'
export const SHORT_ACCRED_SURVEY_NAME = 'short accreditation survey - custom'
export const WAGGA_SURVEY_NAME = 'central wagga medical accreditation survey'
export const OHMC_LEPPINGTON_SURVEY_NAME = 'ohmc leppington - accreditation survey'
export const NEHC_SURVEY_NAME = 'north eastern health centre - accreditation survey'
export const COWRA_MEDIAL_SURVEY_NAME = 'cowra medical associates - accreditation survey'
export const QUINNS_MIRDARIE_SC_SURVEY_NAME = 'quinns mindarie sc - accreditation survey'
export const WINCHELSEA_MEDICAL_CLINIC_SURVEY_NAME = 'winchelsea medical clinic - accreditation survey'

export const SOUTHERN_CROSS_MEDICAL_SURVEY_NAME = 'southern cross medical practice - accreditation survey'
export const BEACH_STREET_FAMILY_PRACTICE_SURVEY_NAME = 'beach street family practice - accreditation survey'
export const KEY_HEALTH_ST_LEONARDS_SURVEY_NAME = 'key health st leonards - accreditation survey'
export const SEAFORD_DAY_AND_NIGHT_CLINIC_SURVEY_NAME = 'seaford day and night clinic - accreditation survey'
export const MYHEALTH_MEDICAL_ZETLAND_SURVEY_NAME = 'myhealth medical centre zetland - accreditation survey'
export const OPTIMAL_HEALTH_MEDICAL_CENTRE_RHODES_SURVEY_NAME = 'optimal health medical centre rhodes central - accreditation survey'

export const OFFLINE_PDF_TYPES = [
    LONG_ACCRED_SURVEY_NAME,
    SHORT_ACCRED_SURVEY_NAME,
    WAGGA_SURVEY_NAME,
    OHMC_LEPPINGTON_SURVEY_NAME,
    NEHC_SURVEY_NAME,
    COWRA_MEDIAL_SURVEY_NAME,
    QUINNS_MIRDARIE_SC_SURVEY_NAME,
    WINCHELSEA_MEDICAL_CLINIC_SURVEY_NAME,
    SEAFORD_DAY_AND_NIGHT_CLINIC_SURVEY_NAME,
    SOUTHERN_CROSS_MEDICAL_SURVEY_NAME,
    BEACH_STREET_FAMILY_PRACTICE_SURVEY_NAME,
    KEY_HEALTH_ST_LEONARDS_SURVEY_NAME,
    MYHEALTH_MEDICAL_ZETLAND_SURVEY_NAME,
    OPTIMAL_HEALTH_MEDICAL_CENTRE_RHODES_SURVEY_NAME
]

export const PDF_REPORT_MAP = {
    'yamantofp2055': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Yamanto-Family-Practice-2723-2055-Report-12122023.pdf?sp=r&st=2023-12-13T00:11:42Z&se=2028-12-31T12:59:59Z&spr=https&sv=2022-11-02&sr=b&sig=AREg2CNTL9daoe2wwzcog7%2F6jcofv1uqQyFrAD40jKk%3D',
    'centralwaggam0336': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Central-Wagga-Medical-2742-0336-Report-230124.pdf?sp=r&st=2024-01-24T06:05:15Z&se=2030-01-24T14:05:15Z&spr=https&sv=2022-11-02&sr=b&sig=jIx3qNGGdo5ui%2F1yYBi60OiERJgtQeF7rnAmEs8r%2FU0%3D',
    'goonellabahmc': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Goonellabah-Medical-Centre-Feedback-Report-%2005022024.pdf?sp=r&st=2024-02-06T05:56:36Z&se=2026-02-06T13:56:36Z&spr=https&sv=2022-11-02&sr=b&sig=Bovm3EFrtRcoAph%2F5NzyRBmcmVmndlW%2FwMtGRDA3l7w%3D',
    'beachmerehc0161': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Beachmere-Health-Centre-4356%200161-Report.pdf?sp=r&st=2024-02-14T06:09:28Z&se=2030-02-14T14:09:28Z&spr=https&sv=2022-11-02&sr=b&sig=xPSYQ7eHqq6inkGHt%2Fi6MOeM13JooLENNOuy4IEn3a4%3D',
    'northmfmc1418': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Well-on-Bay-2559-1418-Report-30012024.pdf?sp=r&st=2024-02-14T06:09:59Z&se=2030-02-14T14:09:59Z&spr=https&sv=2022-11-02&sr=b&sig=H72YwUaJE7uJEpTMkNxGMyZruOXBUDSswUJg%2F8%2BB8rg%3D',
    'GrillettFP-184': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Patient-Feedback-Survey-Report-Grilett-Family-Practice.pdf?sp=r&st=2024-02-14T06:10:34Z&se=2030-02-14T14:10:34Z&spr=https&sv=2022-11-02&sr=b&sig=KTeAJRkDUi3nFCCmd33ehINP67dqtgPWMx%2BhcjOYhkw%3D',
    'hammondh0781': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Hammond-Health-4402%200781-Report-20022024.pdf?sp=r&st=2024-02-24T20:49:59Z&se=2030-02-25T04:49:59Z&spr=https&sv=2022-11-02&sr=b&sig=QuQbCcx%2BFlDmqGGiZJEF%2B%2B7YMCtEuYYcEj%2BCyBaRL3Q%3D',
    'seaforddanc1680': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Seaford-Day-and-Night-Clinic-Report-03042024-3124-1680.pdf?sp=r&st=2024-04-04T05:27:04Z&se=2030-04-04T13:27:04Z&spr=https&sv=2022-11-02&sr=b&sig=hn%2Ft0IshSEAedP2nuql5q53LRYtiKTF0AJbsbhuvM1k%3D',
    'loxleyhfp1045': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Loxley-House-Family-Practice-Report.pdf?sp=r&st=2024-05-06T07:46:52Z&se=2034-05-06T15:46:52Z&spr=https&sv=2022-11-02&sr=b&sig=nRmoP4O2DB4Cqjb99MdIv%2BOIOEl9VUv%2FZ3HsV2cPkIw%3D',
    'myhealthse1360': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Myhealth-South-Eveleigh-Report.pdf?sp=r&st=2024-05-06T07:48:13Z&se=2034-05-06T15:48:13Z&spr=https&sv=2022-11-02&sr=b&sig=Xg0rqdo7esDUq00o6fw%2FpfIR%2B3p9oifXesZu97kmhls%3D',
    'littlehamptonmc1034': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Littlehampton%20Medical%20Centre%20Patient%20Feedback%20Report.pdf?sp=r&st=2024-07-25T06:15:17Z&se=2030-07-25T14:15:17Z&spr=https&sv=2022-11-02&sr=b&sig=5qpBdxEpf%2Bu8U3XLwo19rxoJ%2F%2BhaRFx23PglANbzpSA%3D',
    'southerncmp1733': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Southern%20Cross%20Medical%20Practice%20Patient%20Feedback%20Report.pdf?sp=r&st=2024-07-25T06:16:40Z&se=2030-07-25T14:16:40Z&spr=https&sv=2022-11-02&sr=b&sig=VSz1fSJWErV6V8Z3VvuNt9S5Fqm4Db7RlU%2Bo%2F8Z8cro%3D',
    'winchelseamc2020': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Winchelsea%20Medical%20Clinic%20Patient%20Feedback%20Report.pdf?sp=r&st=2024-07-25T06:17:31Z&se=2030-07-25T14:17:31Z&spr=https&sv=2022-11-02&sr=b&sig=1XZgcFlxvu5IiKDFOqXpMOCR%2B9sfxxTuAGofZ5625J4%3D',
    'quinnsmsc1575': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Quinns%20Mindarie%20Super%20Clinic%20Report.pdf?sp=r&st=2024-07-25T06:18:16Z&se=2030-07-25T14:18:16Z&spr=https&sv=2022-11-02&sr=b&sig=FJXyRX9j7r23ecQmHFi2p2yhH%2BixZZLBIqfUDQmAn%2Fw%3D',
    'petergm1524': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Peter%20Gill%20Medical%20Centre%20Patient%20Feedback%20Report.pdf?sp=r&st=2024-07-25T06:18:57Z&se=2030-07-25T14:18:57Z&spr=https&sv=2022-11-02&sr=b&sig=MUJSyyLypZg9cPEXbAJSZ4ytscmena6Esz8e0mLK9lQ%3D',
    'huntershmp6204': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Hunters%20Hill%20Medical%20Practice%20Report.pdf?sp=r&st=2024-07-25T06:19:47Z&se=2030-07-25T14:19:47Z&spr=https&sv=2022-11-02&sr=b&sig=VmTkLHc4odgD2cz3482vzvJynBxtz%2F7mxWqXbJIJ%2BZQ%3D',
    'worrigeemc2040': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Worrigee%20Medical%20Centre%20Patient%20Feedback%20Report.pdf?sp=r&st=2024-08-15T00:06:01Z&se=2030-08-15T08:06:01Z&spr=https&sv=2022-11-02&sr=b&sig=FW1n2y4xqAJkVWMgU2uxkCgoxVgMPrp7qidyDR4zNkk%3D',
    'vincentiamc1951': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Vincentia%20Medical%20Centre%20Patient%20Feedback%20Report.pdf?sp=r&st=2024-08-15T00:06:57Z&se=2030-08-15T08:06:57Z&spr=https&sv=2022-11-02&sr=b&sig=zHQxQJEC%2Fsgg%2FJlCk%2BpfecHXDUCNxdu4EHsw%2BfKWAJQ%3D',
    'northehc1416': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/North%20Eastern%20Health%20Centre%20Patient%20Feedback%20Report.pdf?sp=r&st=2024-08-15T00:07:38Z&se=2030-08-15T08:07:38Z&spr=https&sv=2022-11-02&sr=b&sig=pvogCf51x68S1YkTvMhZ6g3KGxUkEEoYD12H4nvmVZ4%3D',
    'culburrabmc0444': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Culburra%20Beach%20Medical%20Centre%20Patient%20Feedback%20Report.pdf?sp=r&st=2024-08-15T00:08:13Z&se=2030-08-15T08:08:13Z&spr=https&sv=2022-11-02&sr=b&sig=xAL6AJyEAWwXzydFxACLl9zLSKNaJ38cL6RBgBBn4V4%3D',
    'basinviewmedical0147': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Basin%20View%20Medical%20Centre%20Patient%20Feedback%20Report.pdf?sp=r&st=2024-08-15T00:08:54Z&se=2030-08-15T08:08:54Z&spr=https&sv=2022-11-02&sr=b&sig=aBD0LsDCD9GSZ86P%2BOR%2FoRrugsqE0RQY%2BU6mnaUIAA4%3D',
    'medicalhwr6165': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Medical-Hub-West-Ryde-Patient-Feedback-Report.pdf?sp=r&st=2024-08-29T08:51:22Z&se=2030-08-29T16:51:22Z&spr=https&sv=2022-11-02&sr=b&sig=Y%2BRvCrPIUqETU1DWg04Qd7K5CbenT034WbpP0OMEcp8%3D',
    'optimalhmc1472': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Optimal-Health-Medical-Centre-Gregory-Hills-Patient-Feedback-Report.pdf?sp=r&st=2024-08-29T08:52:57Z&se=2030-08-29T16:52:57Z&spr=https&sv=2022-11-02&sr=b&sig=oblG%2FsjdPZZy%2FFsuXj4%2FpRU5FV%2BghRCS%2BGZIE%2BFZk%2Bg%3D',
    'optimalhmcr1473': 'https://produltimateqpyloafb72db.blob.core.windows.net/ultimateq-files/assets/pdfs/Optimal-Health-Medical-Centre-Rhodes-Central-Patient-Feedback-Report.pdf?sp=r&st=2024-08-29T08:53:42Z&se=2030-08-29T16:53:42Z&spr=https&sv=2022-11-02&sr=b&sig=nTAzUaR546xni1DzV3rq%2FG8GPnRjcBwViYIw6OXZb28%3D'
}

const svgTypes = {
    NEW_SURVEYS: 'new-surveys',
}

const CHART_OPTION_COLORS = {
    gridlineColor: '#4f566d', //used to be #EEF0F1
    axisLabelColor: '#d9ddef', //used to be #9AA4B3
    titleColor: '#fff'
}

const SURVEY_GENDER_HEX_CLOURS = [
    '#0180D5',
    '#e31278',
    '#FFD138',
    '#29AC64'
]

const SURVEY_AGE_HEX_CLOURS = [
    '#29AC64',
    '#65ce25',
    '#0180D5',
    '#4EBBFF',
    '#f9b700',
    '#FF6B00',
    '#E80729'
]

const RECENT_SUBMISSIONS_COLOUR = '#4BC1FF'

const AVERAGE_RESPONSE_SCORES_COLOURS = [
    '#E80729',
    '#FFD138',
    '#BBDF4D',
    '#29AC64'
]

const SURVEY_TOTAL_RESPONSE_COLOURS = [
    '',
    '#E80729',
    '#FFD138',
    '#0180D5',
    '#BBDF4D',
    '#29AC64'
]

const SURVEY_STATS_SCORE_COLOURS = [
    '#E80729',
    '#FFD138',
    '#BBDF4D',
    '#29AC64',
    '#0180D5'
]

//agegroup colors
const SURVEY_COLOURS = [
    'text-green',
    'text-light-green',
    'text-dark-blue',
    'text-light-blue',
    'text-yellow',
    'text-orange',
    'text-red',
]

const SURVEY_RESULTS_COLOURS = [
    'text-light-green',
    'risk-matrix-green-light',
    'text-light-blue',
    'text-orange',
    'text-red',
]

const CLINIC_SUMMARY_FILTER_SURVEYS_OPTIONS = [
    'Total Short Surveys Completed',
    'Total Long Surveys Completed',
    'Avg. Short Surveys Completed',
    'Avg. Long Surveys Completed'
]

const CLINIC_SUMMARY_FILTER_DATE_OPTIONS = [
    { text: 'Last 7 days', offset: 7 },
    { text: 'Last 30 days', offset: 30 },
    { text: 'Last 90 days', offset: 90 },
    { text: '1 year', offset: 365 },
]

const PRACTICE_TYPES = [{
    value: 'GP',
    text: 'General Practice',
    label: 'General Practice'
}, {
    value: 'MDS',
    text: 'Medical Deputising Service',
    label: 'Medical Deputising Service'
}, {
    value: 'AHS',
    text: 'After Hours Clinic',
    label: 'After Hours Clinic'
}, {
    value: 'ABHS',
    text: 'Aboriginal Health Service',
    label: 'Aboriginal Health Service'
}]

const NO_HARDWARE_OBJ = { name: 'No Hardware', price: { formatted_value: 0}, metadata: { hardware: true}}


export {
    ENV,
    svgTypes,
    CHART_OPTION_COLORS,
    SURVEY_GENDER_HEX_CLOURS,
    SURVEY_AGE_HEX_CLOURS,
    SURVEY_COLOURS,
    RECENT_SUBMISSIONS_COLOUR,
    AVERAGE_RESPONSE_SCORES_COLOURS,
    SURVEY_TOTAL_RESPONSE_COLOURS,
    SURVEY_STATS_SCORE_COLOURS,
    CLINIC_SUMMARY_FILTER_SURVEYS_OPTIONS,
    CLINIC_SUMMARY_FILTER_DATE_OPTIONS,
    CS_AUTH0_CLAIM_ID,
    API_KEY_SECRET,
    DEBUG,
    AUTH0_DOMAIN,
    AUTH0_CLIENT_ID,
    AUTH0_ORGANIZATION_ID,
    API_HTTP,
    GMAP_API_KEY,
    IFRAME_CHILD,
    REPORTS_API_HTTP,
    PROJECT_ENV,
    PRACTICE_TYPES,
    STRIPE_PUBLISHABLE_KEY,
    STRIPE_PAYMENT_SUCCESS_URL,
    STRIPE_PAYMENT_CANCEL_URL,
    STRIPE_TEST_MODE,
    BE_API_KEY,
    NODE_API_HTTP,
    MAX_SHORTNAME_LENGTH,
    NO_HARDWARE_OBJ,
    GUID_REGEX,
    HARDWARE_AGREEMENT_LINK,
    HARDWARE_OPTIONS_LINK,
}
