import { NO_HARDWARE_OBJ } from '@/common/constants'

export default {
    tenantId: '',
    parentTenantId: '',
    tenantIds: [],
    constants: [],
    isOpen: false,
    formSet: {},
    modal: {
        isVisible: false,
        componentName: 'ModalDefault',
        componentData: {
            modalClass: ''
        },
    },
    testUser: {
        id: 1,
        userId: 1,
        firstName: 'Test',
        lastName: 'Ted',
        name: 'Test Ted',
        password: 'Random12',
        role: 'super-admin',
        email: 'support@codesource.com.au',
    },
    staticChoices: {},
    loggedInUser: {},
    dashboardFilter: {
        formSetId: '',
        dataRange: {
            startDate: '',
            endDate: ''
        }
    },
    _npsScores: {},
    newSubscription: {},
    selectedHardware: {},
    signUpForm: {
        email: ''
    },
    subscriptions: [],
    hardware: [],
    lineItems: [],
    signupSession: {},
    isNewSub: false
}
