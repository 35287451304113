import Vue from 'vue'
import './plugins/installCompositionApi'
import 'regenerator-runtime'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import VueMeta from 'vue-meta'
import { createPinia, PiniaVuePlugin } from 'pinia'

import { VueReCaptcha } from 'vue-recaptcha-v3'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { StripePlugin } from '@vue-stripe/vue-stripe'

import '@/scss/main.scss'

import { getOrCreateProvider } from './vue-apollo'
import { provide } from '@vue/composition-api'
import { DefaultApolloClient } from '@vue/apollo-composable'
import ability from './plugins/ability'
import VuePhoneNumberInput from 'vue-phone-number-input'
// import '@/plugins/vCalendar'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import VueFriendlyIframe from 'vue-friendly-iframe'

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircle, faComment, faDotCircle, faSquare, } from '@fortawesome/free-regular-svg-icons'
import { faBaby, faBan, faBars, faBriefcaseMedical, faCalendarAlt, faCamera, faCaretDown, faCheck, faCheckCircle, faCheckSquare, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faCircle as fasCircle, faClipboard, faClipboardCheck, faClock, faCog, faComments, faCopy, faDownload, faEnvelope, faExclamationCircle, faExclamationTriangle, faExternalLinkAlt, faEye, faEyeSlash, faFemale, faFileAlt, faFilter, faFolderOpen, faGavel, faHeartbeat, faHouseUser, faInfoCircle, faLock, faLongArrowAltRight, faMale, faMapMarkerAlt, faMinusCircle, faMinusSquare, faPaperclip, faPencilAlt, faPhoneAlt, faPlay, faPlus, faPoll, faQuestionCircle, faRedo, faSearch, faSignInAlt, faSignLanguage, faSignOutAlt, faSmile, faSortUp, faSpinner, faStar, faSyncAlt, faTasks, faTimes, faTimesCircle, faTransgenderAlt, faTrashAlt, faUser, faUserCheck, faUserFriends, faUsers, faUserSecret, faWineBottle, faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Auth0Plugin } from '@/auth'
import { abilitiesPlugin } from '@casl/vue'
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_ORGANIZATION_ID, GMAP_API_KEY, REPORTS_API_HTTP, } from '@/common/constants'

// google autocomplete
import * as GmapVue from 'gmap-vue'

//config.autoAddCss = false
library.add(faPlay, faExclamationTriangle, faUserSecret, faPlus, faPencilAlt, faMapMarkerAlt, faPhoneAlt, faUser, faFileAlt, faQuestionCircle, faExternalLinkAlt, faTrashAlt, faDownload, faComment, faPaperclip, faFilter, faChevronLeft, faChevronDown, faChevronUp, faChevronRight, faCheck, faSignOutAlt, faCheckSquare, faLongArrowAltRight, faLock, faTimes, faCog, faCalendarAlt, faCheckSquare, faBan, faExclamationCircle, faBars, faSquare, faEnvelope, faCaretDown, faTimesCircle, faSortUp, faSearch, faStar, faRedo, faCircle, faMinusCircle, faCheckCircle, faDotCircle, fasCircle, faMinusSquare, faClock, faUsers, faHouseUser, faSmile, faSyncAlt, faTransgenderAlt, faSignLanguage, faGavel, faBriefcaseMedical, faHeartbeat, faBaby, faClipboardCheck, faUserFriends, faComments, faWineBottle, faTasks, faUserCheck, faSignInAlt, faFolderOpen, faPoll, faFemale, faMale, faClipboard, faEye, faEyeSlash, faInfoCircle, faSpinner, faCamera, faCopy, faPrint)
//  options for stripe
const stripeOptions = {
    pk:
        'pk_test_51K8v8BFPcqzbMm9IXMJRv51VgIQsvgEruvtaVQ27Yr0545ULCvQp7Kptgu7xNneTZr7Dog9OAHOYWMZoisJOhEkv00576EIIwn',
    //   stripeAccount: process.env.STRIPE_ACCOUNT,
    //   apiVersion: process.env.API_VERSION,
    //   locale: process.env.LOCALE,
}
// Add libraries
Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('font-awesome-icon', FontAwesomeIcon) // cause vuetify requires it to be this tag
Vue.component('v-phone-input', VuePhoneNumberInput)
Vue.component('vc-date-picker', DatePicker)

Vue.use(VueFriendlyIframe)
Vue.use(VueMeta)

Vue.use(abilitiesPlugin, ability, {
    useGlobalProperties: true,
})

Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_DRF_RECAPTCHA_SITE_KEY,
    loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: true,
    },
})

Vue.use(StripePlugin, stripeOptions)

Vue.use(VueAxios, axios)
// TODO: change this to a fastapi url instead
Vue.axios.defaults.baseURL = REPORTS_API_HTTP || ''
// Vue.axios.defaults.headers['X-API-KEY'] = API_KEY_SECRET

Vue.config.productionTip = false
const apolloProvider = getOrCreateProvider()

const scopes = process.env.VUE_APP_AUTH0_SCOPES?.split(',')?.join(' ') || []
Vue.use(Auth0Plugin, {
    domain: AUTH0_DOMAIN,
    clientId: AUTH0_CLIENT_ID,
    organization: AUTH0_ORGANIZATION_ID,
    advancedOptions: {
        defaultScope: scopes, // change the scopes that are applied to every authz request. **Note**: `openid` is always
        // specified regardless of this setting
    },
    onRedirectCallback: (appState) => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        )
    },
})

Vue.use(GmapVue, {
    load: {
        key: GMAP_API_KEY,
        libraries: 'places',
    },
})

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

const app = new Vue({
    router,
    store,
    pinia,
    vuetify,
    setup() {
        provide(DefaultApolloClient, apolloProvider.defaultClient)
        provide('vuex-store', store)
    },
    render: (h) => h(App),
})

app.$mount('#app')
