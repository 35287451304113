import gql from 'graphql-tag'

const INVITE_PRACTICE = gql`mutation invitePractice($instruction: String!, $email: String!, $shortName: String!) {
    practice: invitePractice(instruction: $instruction, email: $email, short_name: $shortName) {
        status
    }
}
`
export {
    INVITE_PRACTICE
}
