import {getOrCreateProvider} from '@/vue-apollo'
import { GET_USER_BY_AUTH0_ID, GET_USER_BY_EMAIL } from '@/graphql/queries/getUserByEmail.gql'
import camelcaseKeys from 'camelcase-keys'
import store from '@/store'

/** Load user info from our db (hasura) using the user id fetched from auth0 */
export default async authService => {
    // we don't need to worry about this for the moment - this is for loading the user from Hasura
    // maybe check into this later
    const apolloProvider = getOrCreateProvider()

    try {
        let query = GET_USER_BY_EMAIL
        let variables = {email: authService.user.email}
        if (!authService.user.email) {
            query = GET_USER_BY_AUTH0_ID
            variables = {auth0Id: authService.user.sub}
        }
        console.log('======= email', authService.user.email, variables)
        const {data} = await apolloProvider.defaultClient.query({
            query: query,
            variables: variables
        })

        const user = data.users?.length && data.users[0]
        if (!user) {
            throw 'Cannot find user.'
        }
        await store.dispatch('setLoggedInUser', camelcaseKeys(user, { deep: true }))
        authService.isUserInfoLoaded = true
    } catch (e) {
        authService.error = e
        authService.isUserInfoLoaded = false
    }
}
