<template lang="pug">
    div
        div(class="align-middle inline-block min-w-full overflow-hidden")
            DataTable(
                :headers="headers"
                :items="answers"
                :row-click-handler="rowClickHandler"
                :action-handler="openRawDataModal"
                :loading=loading
                :loading-text="'Loading... Please wait'"
            )
</template>

<script>
import DataTable from '@/components/shared/DataTable'
import { ref, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useLazyQuery, useQuery, useResult } from '@vue/apollo-composable'
import { GET_ANSWERS_WITH_COMMENTS } from '@/graphql/queries/formAnswerQueries.gql'
import camelcaseKeys from 'camelcase-keys'
import { GET_CATEGORIES } from '@/graphql/queries/getCategories.gql'
import { isValid, parseISO, format } from 'date-fns'

const { useActions } = createNamespacedHelpers('')

export default {
    name: 'Submissions',
    components: { DataTable },
    props: {
        withComments: {
            type: Boolean,
            default: true
        },
        data: {
            type: Array,
            default: () => []
        },
        tenantId: {
            type: String,
            default: ''
        },
        formSetId: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const headers = ref([
            {text: 'Date Submitted', class: '', value: 'dateSubmitted'},
            {text: 'Time Submitted', class: '', value: 'timeSubmitted'},
            {text: 'Category', class: '', value: 'categoryTitle'},
            {text: 'Comments', class: '', value: 'comment'},
            {text: '', class: '', value: 'editRecord'},
        ])
        const loading = ref(false)
        const { setModalOpen } = useActions(['setModalOpen'])
        const answers = ref([])
        const categories = ref([])
        const answerParams = ref({
            tenantId: props.tenantId,
            formSetId: props.formSetId
        })
        const categoriesParams = ref({
            tenantId: props.tenantId,
            parentTenantId: props.parentTenantId
        })

        const {
            onResult: getAnswersOnResult,
            onError: getAnswersOnError,
            result: getAnswersResult,
            refetch: getAnswersRefetch,
            load: getStatsLoad
        } = useLazyQuery(GET_ANSWERS_WITH_COMMENTS, answerParams)
        const {result: getCategoryResult} = useQuery(GET_CATEGORIES, categoriesParams)

        getStatsLoad()
        const categoryResult = useResult(getCategoryResult, [], data => data.categories)
        const foundResult = useResult(getAnswersResult, [], data => data.answersWithComments.answers)

        // categories.value = [...camelcaseKeys(categoryResult.value, {deep: true})]
        // answers.value = [...camelcaseKeys(foundResult.value, {deep: true})]

        const openRawDataModal = (item) => {
            console.log('item passed', item)
        }

        const rowClickHandler = (answer) => {
            console.log('opening up modal with the answers')
            setModalOpen({
                isVisible: true,
                data: {
                    answer: answer,
                    keepOpen: true,
                    modalClass: 'x-large-modal x-large-height-modal'
                },
                componentName: 'ModalViewSurvey'
            })
        }
        const updateCategory = () => {
            answers.value.forEach(answer => {
                const date = parseISO(answer.updatedAt)
                if (isValid(date)) {
                    answer.dateSubmitted = format(date, 'dd/MM/yyyyy')
                    answer.timeSubmitted = format(date, 'h:mm aaa')
                }
                answer.categoryTitle = categories.value.find(c => c.categoryId === +answer.category)?.name
            })
        }

        watch(() => foundResult.value, (nextVal) => {
            if (!nextVal) return
            answers.value = [...camelcaseKeys(nextVal, {deep: true})]
            updateCategory()
        })
        watch(() => categoryResult.value, (nextVal) => {
            if (!nextVal) return
            categories.value = [...camelcaseKeys(nextVal, {deep: true})]
            updateCategory()
        })
        watch(() => props.formSetId, async (nextVal) => {
            console.log('formsetId', nextVal)
            if (!nextVal) return

            answerParams.value.formSetId = nextVal
            await getAnswersRefetch()
        })

        watch(() => props.data, (nextVal) => {
            answers.value = [...(nextVal || [])]
        })

        return {
            headers,
            answers,
            loading,
            rowClickHandler,
            openRawDataModal
        }
    }
}
</script>
