export const darkTheme = {
    primary: '#F5832A',
    secondary: '#ffffff',
    // All keys will generate theme styles,
    // Here we add a custom `tertiary` color
    warning: '#FF6B00',
    accent: '#000',
    error: '#E80729',
    success: '#29AC64',
    info: '#445865',
    //make vuetify match tailwind colors
    darkblue: '#0180D5',
    lightgreen: '#65ce25',
    lightblue: '#4EBBFF',
}

export const lightTheme = {

}
