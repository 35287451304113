import { ApolloLink } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { API_KEY_SECRET } from '@/common/constants'

// Http endpoint
const uriQuery = process.env.VUE_APP_GRAPHQL_HTTP || ''
const uriMutation = process.env.VUE_APP_GRAPHQL_HTTP || ''
const websocketUrl = process.env.VUE_APP_GRAPHQL_WS || ''
const adminSecret = process.env.VUE_APP_HASURA_ADMIN_SECRET || ''

const omitDeep = (obj, key) => {
    const keys = Object.keys(obj)
    const newObj = {}
    keys.forEach((i) => {
        if (i !== key) {
            const val = obj[i]
            if (val instanceof Date) newObj[i] = val
            else if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key)
            else if (typeof val === 'object' && val !== null) newObj[i] = omitDeep(val, key)
            else newObj[i] = val
        }
    })
    return newObj
}

const omitDeepArrayWalk = (arr, key) => {
    return arr.map((val) => {
        if (Array.isArray(val)) return omitDeepArrayWalk(val, key)
        else if (typeof val === 'object') return omitDeep(val, key)
        return val
    })
}

const cleanTypenameLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        operation.variables = omitDeep(operation.variables, '__typename')
    }
    return forward(operation).map((data) => {
        return data
    })
})

const wsLink = new ApolloLink(operation => {
    const context = operation.getContext()

    // Create a new websocket link per request
    return new WebSocketLink({
        uri: websocketUrl,
        options: {
            reconnect: true,
            connectionParams: {
                headers: context.headers
            }
        },
    }).request(operation)
    // Instead of forward(), use websocketLink's request method

})

// Goes to CreateProvider.link as ApolloLink.from(<Array>)
export default [
    ApolloLink.split(
        (operation) => {
            const definition = getMainDefinition(operation.query)
            // console.log('Apollo split First operation', operation)
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            )
        },
        wsLink,
        ApolloLink.split(
            ({ query }) => {
                const definition = getMainDefinition(query)
                // console.log('Apollo split Second definition', definition)
                return definition.operation === 'query'
            },
            new HttpLink({
                headers: {
                    'x-hasura-admin-secret': adminSecret,
                    'X-API-KEY': API_KEY_SECRET
                },
                uri: uriQuery,
            }),
            ApolloLink.from(
                [cleanTypenameLink, new HttpLink({
                    headers: {
                        'x-hasura-admin-secret': adminSecret,
                        'X-API-KEY': API_KEY_SECRET
                    },
                    uri: uriMutation,
                })]
            )
        ),
    ),
]
