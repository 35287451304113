import { axiosInstance } from '@/utils/axios'
import { NODE_API_HTTP } from '@/common/constants'
import camelcaseKeys from 'camelcase-keys'

export class SignupSessionService {
    constructor() {
        this.nodeApi = `${NODE_API_HTTP}/api/v1/signup-session`
    }

    async createSignupSession(signupSession) {
        const { data: newSignupSession } = await axiosInstance.post(this.nodeApi, {
            ...signupSession,
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        // only doing the top level of keys for now
        return camelcaseKeys(newSignupSession)
    }
    async updateSignupSession(signupSessionId, signupSession) {
        const url = `${this.nodeApi}/${signupSessionId}`
        const { data: newSignupSession } = await axiosInstance.post(url, {
            ...signupSession
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        // only doing the top level of keys for now
        return camelcaseKeys(newSignupSession)
    }
    async getSignupSession(signupSessionId) {
        const url = `${this.nodeApi}/${signupSessionId}`
        const { data: signupSession } = await axiosInstance.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        // only doing the top level of keys for now
        return camelcaseKeys(signupSession)
    }

    async getSignupSessionByKey(key, property) {
        const url = `${this.nodeApi}?key=${key}&property=${property}`
        const { data: signupSession } = await axiosInstance.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        // only doing the top level of keys for now
        return camelcaseKeys(signupSession)
    }

    async addHardware(signupSessionId, extraHardware) {
        const url = `${this.nodeApi}/${signupSessionId}/add-hardware`
        const { data: updatedSignupSession } = await axiosInstance.post(url, { extraHardware }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        // only doing the top level of keys for now
        return camelcaseKeys(updatedSignupSession)
    }

    // this function is to remove the hardware the user has added if they cancelled
    // the stripe payment before
    async removeLastAddedHardware(signupSessionId) {
        const url = `${this.nodeApi}/${signupSessionId}/remove-last-hardware`
        try {
            // since delete will be empty, we don't have to worry about the return object
            await axiosInstance.delete(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            // only doing the top level of keys for now
            return true
        } catch (e) {
            console.error(e)
            return false
        }
    }

    async getLastAddedHardware(signupSessionId) {
        const url = `${this.nodeApi}/${signupSessionId}/last-added-hardware`
        try {
            // since delete will be empty, we don't have to worry about the return object
            const { data: hardwareList } = await axiosInstance.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            // only doing the top level of keys for now
            return hardwareList
        } catch (e) {
            console.error(e)
            return []
        }
    }
}
