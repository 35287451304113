import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,_vm._b({attrs:{"accordion":"","multiple":""},model:{value:(_vm.panelModel),callback:function ($$v) {_vm.panelModel=$$v},expression:"panelModel"}},'v-expansion-panels',_vm.$attrs,false),_vm._l((_vm.categories),function(category,index){return _c(VExpansionPanel,{key:index},[_c(VExpansionPanelHeader,{staticClass:"font-weight-bold text-off-white p-3"},[_vm._v(_vm._s(category.name))]),_c(VExpansionPanelContent,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.getItemsByCategory(category.categoryId),"loading":_vm.loading,"loading-text":_vm.loadingText,"items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"click:row":_vm.rowClickHandler},scopedSlots:_vm._u([{key:"item.excellent",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.getHighestClasses(item, 'excellent')},[_vm._v(" "+_vm._s(item.excellent || 0))])]}},{key:"item.fair",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.getHighestClasses(item, 'fair')},[_vm._v(" "+_vm._s(item.fair || 0))])]}},{key:"item.good",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.getHighestClasses(item, 'good')},[_vm._v(" "+_vm._s(item.good || 0))])]}},{key:"item.poor",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.getHighestClasses(item, 'poor')},[_vm._v(" "+_vm._s(item.poor || 0))])]}},{key:"item.veryGood",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.getHighestClasses(item, 'veryGood')},[_vm._v(_vm._s(item.veryGood || 0))])]}},{key:"item.na",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.getHighestClasses(item, 'na')},[_vm._v(_vm._s(item.na || 0))])]}}],null,true)})],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }