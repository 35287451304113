<template lang="pug">
    div
        BackLink.mb-2(:linkText="backLinkText" :backRoute="backLinkRoute")
        .flex.items-center.justify-between.mb-4
            Title(:title="`${name}`")

            .flex.items-center
                vc-date-picker(
                    v-model="rangeFilter"
                    class="inline-block h-full border border-navy-light box-bg rounded mr-2 cursor-pointer"
                    is-range
                    mode="date"
                )
                    template(v-slot="{ inputValue, inputEvents, togglePopover }")
                        fa-icon(
                            icon="calendar-alt"
                            class="ml-2 text-grey-2 cursor-pointer"
                        )
                        input(
                            class="px-2 py-1 w-200"
                            :value="getDateDisplay(inputValue)"
                            v-on="inputEvents"
                            readonly
                            @click="togglePopover"
                        )
                v-btn(
                    class=""
                    depressed
                    raised
                    @click="resetFilter"
                )
                    | Reset
        .grid.grid-cols-12.gap-4.mb-4
            div(class="col-span-4 xl:col-span-4 p-4 bg-navy-light rounded")
                h3 All answers
                .mt-2(v-if="!statsLoading")
                    div
                        DoughnutChart(
                            v-if="!loading && questionByHeaderHasData"
                            :height="150"
                            :chart-data="questionByHeaderChartData"
                            :options="chartOptions"
                        )
                        .w-full(v-else)
                            | No data
                    //.flex.flex-wrap
                        .font-bold.mr-4(
                            v-for="(header, index) in mappedHeaders" :key="`age-${index}`"
                        )
                            span.text-white.mr-1.text-sm {{getQuestionHeaderTotal(header.value, totalByQuestionHeaderData)}}
                            span.uppercase.text-xs.whitespace-pre(:class="getStatColour(index)") {{header.text}}
                .flex.items-center.justify-center.h-20.w-full(v-else)
                    Loader(class="w-12 h-12")

            div(class="col-span-4 xl:col-span-4 p-4 bg-navy-light rounded")
                h3 Gender
                .mt-2(v-if="!statsLoading")
                    div
                        DoughnutChart(
                            v-if="!loading"
                            :height="150"
                            :chart-data="totalGenderChartData"
                            :options="chartOptions"
                        )
                    //.flex.flex-col
                        .font-bold.mr-4
                            span.text-white.mr-1.text-sm {{totalMales}}
                            span.uppercase.text-dark-blue.text-xs Male
                        .font-bold.mr-4
                            span.text-white.mr-1.text-sm {{totalFemales}}
                            span.uppercase.text-chart-pink.text-xs Female
                        .font-bold.mr-4
                            span.text-white.mr-1.text-sm {{totalNonBinary}}
                            span.uppercase.text-orange.text-xs Non binary
                        .font-bold.mr-4
                            span.text-white.mr-1.text-sm {{totalPreferNotToSay}}
                            span.uppercase.text-green.text-xs Prefer not to say
                .flex.items-center.justify-center.h-20.w-full(v-else)
                    Loader(class="w-12 h-12")

            div(class="col-span-4 xl:col-span-4 p-4 bg-navy-light rounded")
                h3 Age Groups
                .mt-2(v-if="!statsLoading")
                    div
                        DoughnutChart(
                            v-if="!loading"
                            :height="150"
                            :chart-data="ageStatsChartData"
                            :options="chartOptions"
                        )
                    //.flex.flex-wrap
                        .font-bold.mr-4.flex-grow(
                            v-for="(ageStat, index) in ageStats" :key="`age-${index}`"
                        )
                            span.text-white.mr-1.text-sm {{getFormatted(ageStat.value)}}
                            span.uppercase.text-xs.whitespace-pre(:class="getStatColour(index)") {{ageStat.text}}
                .flex.items-center.justify-center.h-20.w-full(v-else)
                    Loader(class="w-12 h-12")
        Tabs.flex.flex-col.mt-6(
            :headers="headers"
            :questions="questions"
            :categories="categories"
            :loading=loading
            :form-set-id="formSetId"
        )
</template>

<script>
import Title from '@/components/basic/Title'
import { ref, watch } from '@vue/composition-api'
import camelcase from 'camelcase'
import { SURVEY_AGE_HEX_CLOURS, SURVEY_COLOURS, SURVEY_GENDER_HEX_CLOURS, svgTypes, CHART_OPTION_COLORS } from '@/common/constants'
import SmallTile from '@/components/shared/SmallTile'
import DataTable from '@/components/shared/DataTable'
import Loader from '@/assets/loader.svg'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import DoughnutChart from '@/components/charts/DoughnutChart'
import BackLink from '@/components/shared/BackLink'
import Tabs from '@/components/statistics/partials/Tabs'
import { getFormatted } from '@/modules/formatters'
import camelcaseKeys from 'camelcase-keys'
const { useState } = createNamespacedHelpers('app')

const getStatColour = (index) => {
    if (index == null || index < 0 || index > SURVEY_COLOURS.length) return ''
    return SURVEY_COLOURS[index]
}

const getHexColour = (index) => {
    if (index == null || index < 0 || index > SURVEY_AGE_HEX_CLOURS.length) return ''
    return SURVEY_AGE_HEX_CLOURS[index]
}

const getQuestionHeaderTotal = (header, questionHeaderTotals) => {
    if (!header || !Object.keys(questionHeaderTotals || {}).length) return 0
    return questionHeaderTotals[header]
}

const genderKeys = ['male', 'female', 'non_binary', 'prefer_not_to_say']


export default {
    name: 'FormStatistics',
    components: { Title, SmallTile, DataTable, Loader, DoughnutChart, BackLink, Tabs },
    emits: ['reload-stats'],
    props: {
        stats: {
            type: Object,
            default: () => {}
        },
        formName: {
            type: String,
            default: ''
        },
        statsLoading: {
            type: Boolean,
            default: true
        },
        formSetId: {
            type: String,
            default: ''
        },
    },
    setup(props, { emit }) {
        // const statsRes = ref({})
        const defaultDateRange = {
            start: '',
            end: ''
        }
        const { constants } = useState(['constants'])
        const surveyAnswers = ref([])
        const rangeFilter = ref({...defaultDateRange})

        const questionByHeaderHasData = ref(false)
        const backLinkText = ref('Surveys')
        const backLinkRoute = ref({name: 'Forms'})
        const questions = ref([])
        const categories = ref([])
        const totalMales = ref(0)
        const totalFemales = ref(0)
        const totalNonBinary = ref(0)
        const totalPreferNotToSay = ref(0)
        const headers = ref([])
        const mappedHeaders = ref([])
        const loading = ref(true)
        const ageStats = ref({})
        const name = ref(props.formName)
        const totalByQuestionHeaderData = ref()
        const totalGenderChartData = ref({
            labels: ['Males', 'Females', 'Non binary', 'Prefer not to say'],
            datasets: [{
                label: 'Total number based on gender',
                backgroundColor: SURVEY_GENDER_HEX_CLOURS,
                borderColor: '#414659',
                data: []
            }]
        })
        const ageStatsChartData = ref({
            labels: [],
            datasets: [{
                label: 'Total number based on age',
                backgroundColor: [...SURVEY_AGE_HEX_CLOURS],
                borderColor: '#414659',
                data: []
            }]
        })
        const questionByHeaderChartData = ref({
            labels: [],
            datasets: [{
                label: 'Total number based on questions answered',
                backgroundColor: [...SURVEY_AGE_HEX_CLOURS.reverse()],
                borderColor: '#414659',
                data: []
            }]
        })
        const chartOptions = ref({
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: true,
                position: 'right',
                maxWidth: 150,
                labels: { 
                    boxWidth: 12,
                    fontColor: CHART_OPTION_COLORS.axisLabelColor
                }
            },
            plugins: {
                datalabels: {
                    display: false
                }
            }
        })

        const getDateDisplay = (dateRange) => {
            console.log('dateRanges', dateRange)
            if (!dateRange.start || !dateRange.end) return 'All time'
            return `${dateRange.start} - ${dateRange.end}`
        }

        const resetData = () => {
            ageStats.value = []

            totalMales.value = 0
            totalFemales.value = 0
            totalByQuestionHeaderData.value = {}
            ageStatsChartData.value.datasets[0].data = []
            ageStatsChartData.value.labels = []
            // ageStatsChartData.value.datasets[0].backgroundColor = []
            totalGenderChartData.value.datasets[0].data = []

            questionByHeaderHasData.value = false
            questionByHeaderChartData.value.labels = []
            questionByHeaderChartData.value.datasets[0].data = []
        }

        const resetFilter = () => {
            rangeFilter.value = {...defaultDateRange}
        }

        const getSurveyAnswers = (first, _) => {
            surveyAnswers.value = first.filter(c => c.group.toLowerCase() === 'survey_answer')
        }
        if (constants?.value.length) getSurveyAnswers(constants.value)

        watch(() => [...constants.value], getSurveyAnswers)

        watch(() => rangeFilter.value, (newValue) => {
            console.log('new date filter', newValue)
            resetData()

            emit('reload-stats', {...newValue})
        })
        watch(() => props.stats, (first, second) => {
            const flatQuestions = []
            const flatAge = []
            const questionList = first.questions
            categories.value = first.categories.map((category) => camelcaseKeys(category.category, { deep: true }))
            // const questionKeys = Object.keys(questionList)
            const ageKeys = Object.keys(first.age).sort()
            totalMales.value = first.gender?.male?.total || 0
            totalFemales.value = first.gender?.female?.total || 0
            totalNonBinary.value = first.gender?.non_binary?.total || 0
            totalPreferNotToSay.value = first.gender?.prefer_not_to_say?.total || 0

            totalByQuestionHeaderData.value = first.totalByQuestionHeader || {}
            totalGenderChartData.value.datasets[0].data = [
                totalMales.value || 0, totalFemales.value || 0, totalNonBinary.value || 0, totalPreferNotToSay.value || 0
            ]

            if (totalByQuestionHeaderData.value && totalByQuestionHeaderData.value.constructor.name === 'Object') {
                questionByHeaderChartData.value.datasets[0].data = Object.values(totalByQuestionHeaderData.value || 0)
                questionByHeaderHasData.value = true
            }
            //age ageStats
            const ageLabels = []
            const ageData = []
            for (let key of ageKeys) {
                flatAge.push({text: key, value: first.age[key]?.total || 0})
                ageLabels.push(key)
                ageData.push(first.age[key]?.total || 0)
            }
            ageStatsChartData.value.labels = [...ageLabels]
            ageStatsChartData.value.datasets[0].data = [...ageData]
            ageStats.value = [...flatAge]
            for(let item of questionList) {
                // now we map the value to the appropriate header
                item = camelcaseKeys(item, { deep: true })
                const question = item.text
                const categoryId = item.categoryId
                let total = 0
                const newObj = Object.keys(item.stats).reduce((obj, k) => {
                    const surveyAnswerType = surveyAnswers.value.find(ag => +ag.value === +k)
                    if (surveyAnswerType) {
                        obj[camelcase(surveyAnswerType.text).replace(/[^\w\s]/gi, '')] = item.stats[k]
                        total += Number(item.stats[k])
                    }
                    return obj
                }, {})
                newObj['total'] = total
                flatQuestions.push({question, categoryId, ...newObj})
            }
            questions.value = [...flatQuestions]
            mappedHeaders.value = first.questionsHeaders.map(q => {
                const surveyAnswerType = surveyAnswers.value.find(
                    ag => Number(ag.value) === Number(q)
                )
                const text = surveyAnswerType?.text || q
                questionByHeaderChartData.value.labels.push(text)
                return {text: text, value: Number(q), align: 'right', class: '', sortable: true}
            })
            headers.value = [
                {text: 'Question', class: '', value: 'question'},
                ...mappedHeaders.value,
                {text: 'Total', class: '', value: 'total', align: 'right', sortable: true}]
                .map((h) => {
                    return {
                        text: h.text,
                        value: camelcase(h.text).replace(/[^\w\s]/gi, ''),
                        class: h.class || '',
                        align: h.align || '',
                        sortable: h.sortable,
                    }
                })

            // we reverse it here so we can display it in the card
            mappedHeaders.value = mappedHeaders.value.reverse()
            loading.value = false
        })

        return {
            ageStats,
            totalMales,
            totalFemales,
            totalNonBinary,
            totalPreferNotToSay,
            questions,
            categories,
            headers,
            loading,
            name,
            totalGenderChartData,
            ageStatsChartData,
            chartOptions,
            mappedHeaders,
            totalByQuestionHeaderData,
            questionByHeaderChartData,
            backLinkText,
            backLinkRoute,
            rangeFilter,
            questionByHeaderHasData,
            svgTypes,
            getFormatted,
            getStatColour,
            getQuestionHeaderTotal,
            getDateDisplay,
            resetFilter
        }
    }
}
</script>
